import { getApp, getDB } from './auth'
const db = getDB()
const app = getApp()
const cmd = db.command

export const getObjectById = (
  colllectionName: string,
  id: string,
): Promise<any> => {
  return new Promise((resolve) => {
    db.collection(colllectionName)
      .doc(id)
      .get()
      .then((result) => {
        resolve(result)
      })
      .catch((error) => {
        console.error(error)
        resolve(null)
      })
  })
}
export async function updateObjectById(
  colllectionName: string,
  id: string,
  key: string,
  value: any,
): Promise<boolean> {
  let params = {
    [key]: value,
  }
  return new Promise((resolve) => {
    db.collection(colllectionName)
      .doc(id)
      .update(params)
      .then((res) => {
        console.log(res)
        resolve(true)
      })
      .catch(() => {
        resolve(false)
      })
  })
}

export async function updateObjectPropsById(
  colllectionName: string,
  id: string,
  data: Map<string, any>,
): Promise<boolean> {
  let params: { [key: string]: any } = {}
  data.forEach((value, key) => {
    params[key] = value
  })
  return new Promise((resolve) => {
    db.collection(colllectionName)
      .doc(id)
      .update(params)
      .then((res) => {
        console.log(res)
        resolve(true)
      })
      .catch(() => {
        resolve(false)
      })
  })
}

export async function getObjects(
  colllectionName: string,
  page: number,
  limit: number,
  params: any,
): Promise<any[]> {
  return new Promise((resolve): void => {
    db.collection(colllectionName)
      .where(params)
      .skip(page * limit)
      .limit(limit)
      .get()
      .then((res) => {
        const arr = res.data
        resolve(arr)
      })
      .catch(() => {
        resolve([])
      })
  })
}

export async function countObject(
  colllectionName: string,
  params: any,
): Promise<number> {
  return new Promise((resolve): void => {
    db.collection(colllectionName)
      .where(params)
      .count()
      .then((res) => {
        resolve(res.total)
      })
      .catch(() => {
        resolve(0)
      })
  })
}

export async function deleteObject(
  colllectionName: string,
  id:string
): Promise<boolean> {
  return new Promise((resolve): void => {
    db.collection(colllectionName).doc(id).remove()
      .then((res) => {
        resolve(res)
      })
      .catch(() => {
        resolve(false)
      })
  })
}

export function packEQParams(condition: any) {
  const params: Map<string, any> = new Map<string, any>()
  for (const key in condition) {
    if (Object.prototype.hasOwnProperty.call(condition, key)) {
      const element = condition[key]
      params.set(key, cmd.eq(element))
    }
  }
  return Object.fromEntries(params)
}

export function callFunction(name: string, data: any): Promise<any> {
  return new Promise((resolve) => {
    app
      .callFunction({ name, data })
      .then((res) => {
        resolve(res.result)
      })
      .catch((err) => {
        resolve(null)
      })
  })
}

export const createObject = async (
  collectionName:string,
  newNode: any,
): Promise<string> => {
  return new Promise((resolve) => {
    const collection = db.collection(collectionName)
    collection
      .add(newNode)
      .then((res:any) => {
        console.log(res);
        resolve(res.id)
      })
      .catch((err) => {
        
        resolve('')
      })
  })
}
