import { getDB } from './auth'
import mm from 'memoizee'
import {
  countObject,
  createObject,
  getObjects,
  packEQParams,
  updateObjectById,
  updateObjectPropsById,
} from './common.service'
// import { v4 as uuidv4 } from 'uuid'
import { NewIDVO, SubjectGenre } from './subjects.service'
const db = getDB()
const cmd = db.command
const COLLECTION_NAME: string = 'subject-matrix'
const NODE_COLLECTION_NAME: string = 'node-matrix'

//Matrix Part
export enum MatrixGenre {
  PICTURE = 'picture',
  CHART = 'chart',
  TEXT = 'text',
}
export type NewMatrixSubjectVO = {
  subtitle: string
  directions: string
  image: string
  genre: string // graduate | cet4 | cet6
  genre2: string // A = 小作文 B = 大作文
  type: MatrixGenre //图画，文字
  specificType: string //图画，文字 TODO: 具体类型共有多少类 稍后移除
  archived: boolean
}
//问题节点
export type MatrixNodeVO = {
  _id?: string
  text: string
  parentNode?: string //父节点
  subNodes?: MatrixNodeVO[] //下面的分支节点
  options: MatrixOptionNodeVO[]
  belongsTo: string //所属主题
  isRoot: boolean //是否根节点
  fromOption?: string //
  cloneFrom?: string //数据中不保存，用于tree clone
}
//答案节点
export type MatrixOptionNodeVO = {
  _id?: string
  text: string
  phrase: string //内容
  translation: string //翻译
  functionality: string //功能
  belongsToNode: string //所属问题节点
  belongsToSubject: string //所属主题
  badChoice: boolean
}
export type MatrixSubjectVO = NewMatrixSubjectVO & NewIDVO

export const getCount = async (condition: any | null): Promise<number> => {
  const collectionName = COLLECTION_NAME
  let result
  if (condition === null) {
    result = await db.collection(collectionName).count()
    return result.total
  } else {
    const params = packEQParams(condition)
    result = await countObject(collectionName, params)
    return result
  }
}
export const createSubject = async (
  newSubject: NewMatrixSubjectVO,
): Promise<string> => {
  return new Promise((resolve) => {
    const collection = db.collection(COLLECTION_NAME)
    collection
      .add(newSubject)
      .then((res: any) => {
        resolve(res.id)
      })
      .catch((err) => {
        resolve('')
      })
  })
}
export const getSubjects = async (
  page: number,
  limit: number,
): Promise<Array<MatrixSubjectVO>> => {
  const collectionName = COLLECTION_NAME
  return new Promise((resolve) => {
    db.collection(collectionName)
      .skip(page * limit)
      .limit(limit)
      .get()
      .then((res) => {
        const arr = res.data
        const result: Array<MatrixSubjectVO> = []
        arr.forEach((element: any) => {
          result.push(parseSubject(element))
        })
        resolve(result)
      })
      .catch(() => {
        resolve([])
      })
  })
}
export const getSubjectsCondition = mm(
  async (
    page: number,
    limit: number,
    condition: any,
  ): Promise<Array<MatrixSubjectVO>> => {
    return new Promise((resolve) => {
      const collectionName = COLLECTION_NAME
      const params = packEQParams(condition)
      getObjects(collectionName, page, limit, params).then((res) => {
        const arr = res
        const result: Array<MatrixSubjectVO> = []
        arr.forEach((element: any) => {
          result.push(parseSubject(element))
        })
        resolve(result)
      })
    })
  },
  {
    promise: true,
    normalizer: function (args) {
      return JSON.stringify(args)
    },
  },
)
export const getNodeByName = async (
  subjectId: string,
  name: string,
): Promise<Array<MatrixNodeVO>> => {
  const res = await db
    .collection(NODE_COLLECTION_NAME)
    .where({
      belongsTo: cmd.eq(subjectId),
      text: cmd.eq(name),
    })
    .get()
  const arr = res.data
  const result: Array<MatrixNodeVO> = []
  arr.forEach((element: any) => {
    result.push(parseNode(element))
  })
  return result
}
export const updateSubject = async (
  newObject: MatrixSubjectVO,
  key: string,
  value: any,
): Promise<boolean> => {
  const collectionName = COLLECTION_NAME
  return updateObjectById(collectionName, newObject._id!, key, value)
}

export const newEmptyObject = (type: MatrixGenre): NewMatrixSubjectVO => {
  return {
    type,
    directions: '',
    genre: SubjectGenre.GRADUATE,
    genre2: 'A',
    subtitle: '',
    archived: true,
    image: '',
    specificType: '1',
  }
}

const parseSubject = (element: any): MatrixSubjectVO => {
  return {
    _id: String(element._id),
    directions: String(element.directions),
    image: element.image === undefined ? '' : String(element.image),
    subtitle: String(element.subtitle),
    genre: String(element.genre),
    genre2: String(element.genre2),
    archived: Boolean(element.archived),
    type: String(element.type) as MatrixGenre,
    specificType: String(element.specificType || '1'),
  }
}

export const getDemoMatrixSubject = (): MatrixSubjectVO => {
  return {
    _id: 'ea7a855c6389c01c009be1c47bb228d7',
    archived: false,
    directions: 'traditional class vs. online class',
    genre: 'graduate',
    genre2: 'A',
    type: MatrixGenre.TEXT,
    image: '',
    subtitle: 'traditional class vs. online class',
    specificType: '2',
  }
}
export const subjectToNode = (subject: MatrixSubjectVO): MatrixNodeVO => {
  return {
    _id: subject._id,
    text: subject.directions,
    subNodes: [],
    options: [],
    belongsTo: subject._id,
    isRoot: true,
  }
}
export const retrieveNodes = (
  allNode: MatrixNodeVO[],
  node: MatrixNodeVO,
  initArray: Array<MatrixNodeVO>,
): Array<MatrixNodeVO> => {
  initArray.unshift(node)
  if (node.parentNode !== undefined) {
    const pNode = allNode.find((n) => n._id === node.parentNode)
    if (pNode) {
      return retrieveNodes(allNode, pNode, initArray)
    } else {
      return initArray
    }
  }
  return initArray
}
export const retrieveOptions = (
  option: MatrixOptionNodeVO,
  nodes: MatrixNodeVO[],
  list: MatrixOptionNodeVO[],
): MatrixOptionNodeVO[] => {
  list.push(option)
  const node = nodes.find((n) => n._id === option.belongsToNode)

  if (node) {
    const parentNode = nodes.find((n) => n._id === node.parentNode)
    if (parentNode) {
      const prevOption = parentNode.options.find(
        (o) => o._id === node.fromOption,
      )
      if (prevOption) {
        return retrieveOptions(prevOption, nodes, list)
      }
    }
  }
  return list
}
export const findNodeContext = (
  node: MatrixNodeVO,
  nodes: MatrixNodeVO[],
): Array<MatrixNodeVO | undefined> => {
  const before = nodes.find((n) => n._id === node.parentNode)
  const after = nodes.find((n) => n.parentNode === node._id)
  return [before, node, after]
}
export const createMatrixNode = async (
  newNode: MatrixNodeVO,
): Promise<string> => {
  return createObject(NODE_COLLECTION_NAME, newNode)
}
export const updateNodeByID = async (
  node: MatrixNodeVO,
  data: Map<string, any>,
): Promise<boolean> => {
  return updateObjectPropsById(NODE_COLLECTION_NAME, node._id!, data)
}

const parseNode = (element: any): MatrixNodeVO => {
  return {
    _id: String(element._id),
    text: String(element.text),
    parentNode: String(element.parentNode),
    options: element.options as MatrixOptionNodeVO[],
    belongsTo: String(element.belongsTo),
    isRoot: Boolean(element.isRoot),
    fromOption: String(element.fromOption),
    cloneFrom: String(element.cloneFrom || ''),
  }
}

export const getMatrixNodes = async (
  subjectId: string,
): Promise<Array<MatrixNodeVO>> => {
  const collectionName = NODE_COLLECTION_NAME
  return new Promise((resolve) => {
    db.collection(collectionName)
      .where({
        belongsTo: subjectId,
      })
      .get()
      .then((res) => {
        const arr = res.data
        const result: Array<MatrixNodeVO> = []
        arr.forEach((element: any) => {
          result.push(parseNode(element))
        })
        resolve(result)
      })
      .catch(() => {
        resolve([])
      })
  })
}
export const deleteNodeFromOption = async (
  option: MatrixOptionNodeVO,
): Promise<boolean> => {
  await db
    .collection(NODE_COLLECTION_NAME)
    .where({
      fromOption: cmd.eq(option._id!),
    })
    .remove()
  return true
}
export const scanSubNodes = (node: MatrixNodeVO, nodes: MatrixNodeVO[]) => {
  const nodesToDelete = extinctionNode(node, nodes, [node])
  return nodesToDelete
}
const extinctionNode = (
  node: MatrixNodeVO,
  nodes: MatrixNodeVO[],
  list: MatrixNodeVO[],
) => {
  const optionTargets = node.options.map((o) => o._id)
  const targetNodes = nodes.filter((n) => {
    if (n.fromOption) {
      const included =
        optionTargets.includes(n.fromOption) && n.parentNode === node._id
      return included
    }
    return false
  })
  list.push(...targetNodes)
  for (let index = 0; index < targetNodes.length; index++) {
    const element = targetNodes[index]
    extinctionNode(element, nodes, list)
  }
  return list
}
export const cloneSubTree = async (
  fromOption: string,
  parentNodeId: string,
  copyFromnode: MatrixNodeVO,
  nodes: MatrixNodeVO[],
): Promise<boolean> => {
  const offsprings = scanSubNodes(copyFromnode, nodes)
  console.log('offsprings', offsprings)
  const clonedNodes: MatrixNodeVO[] = []
  for (let index = 0; index < offsprings.length; index++) {
    const element = offsprings[index]
    if (index === 0) {
      element.parentNode = parentNodeId
      element.fromOption = fromOption
    }
    const newNode = await cloneNode(element)
    clonedNodes.push(newNode)
  }
  console.log('clonedNodes', clonedNodes)
  const restructuredNodes = clonedNodes.map((cn) => {
    const target = clonedNodes.find((n) => n.cloneFrom === cn.parentNode)
    if (target) {
      return {
        ...cn,
        parentNode: target._id,
      }
    }
    return cn
  })
  //Update all new nodes
  console.log('restructuredNodes', restructuredNodes)
  const params = new Map<string, any>()
  for (let index = 0; index < restructuredNodes.length; index++) {
    const element = restructuredNodes[index]
    params.set('parentNode', element.parentNode)
    await updateNodeByID(element, params)
  }
  return true
}
const cloneNode = async (fromNode: MatrixNodeVO): Promise<MatrixNodeVO> => {
  const newNode = {
    ...fromNode,
    cloneFrom: fromNode._id,
  }
  delete newNode['_id']
  //mock node creation
  newNode._id = await createMatrixNode(newNode)
  return newNode
}

export const deleteNodes = async (
  nodesAndDecendents: string[],
): Promise<boolean> => {
  const target = await db
    .collection(NODE_COLLECTION_NAME)
    .where({
      _id: cmd.in(nodesAndDecendents),
    })
    .remove()
  console.log(target)
  return true
}

export const deleteMatrixSubject = async (subject: MatrixSubjectVO) => {
  try {
    await db
      .collection(NODE_COLLECTION_NAME)
      .where({
        belongsTo: cmd.eq(subject._id),
      })
      .remove()
    await db.collection(COLLECTION_NAME).doc(subject._id).remove()
    return true
  } catch (error) {
    return false
  }
}

export const getDemoMatrixNodes = (
  subject: MatrixSubjectVO,
): MatrixNodeVO[] => {
  const nodes: Array<MatrixNodeVO> = []
  // const n1_1: MatrixNodeVO = createDemoNode(
  //   true,
  //   subject,
  //   '中心思想是？',
  // )
  // nodes.push(n1_1)

  // const n2_1: MatrixNodeVO = createDemoNode(
  //   false,
  //   subject,
  //   '你支持哪方？',
  //   n1_1._id,
  // )
  // createDemoOptionOnNode(subject, 'traditional class', n2_1)
  // createDemoOptionOnNode(subject, 'online class', n2_1)
  // nodes.push(n2_1)

  // const n3_1: MatrixNodeVO = createDemoNode(
  //   false,
  //   subject,
  //   '支持traditional class的原因是？',
  //   n2_1._id,
  // )
  // createDemoOptionOnNode(subject, '效率高', n3_1)
  // createDemoOptionOnNode(subject, '氛围好', n3_1)
  // nodes.push(n3_1)

  // const n3_2: MatrixNodeVO = createDemoNode(
  //   false,
  //   subject,
  //   '支持的online class的原因是？',
  //   n2_1._id,
  // )
  // createDemoOptionOnNode(subject, '便捷', n3_1)
  // createDemoOptionOnNode(subject, '发展前景好', n3_1)
  // nodes.push(n3_2)
  return nodes
}
