import { getDB } from './auth'
import mm from 'memoizee'
import {
  countObject,
  getObjects,
  packEQParams,
  updateObjectById,
} from './common.service'
const db = getDB()
const cmd = db.command
const COLLECTION_NAME: string = 'subject'
const CET_COLLECTION_NAME: string = 'subject-cet'

export type NewSubjectVO = {
  directions: string
  ideas: string[]
  image: string
  keywords: string
  part: 'A' | 'B'
  type: '1' | '2'
  points: number
  subtitle: string
  year: number
  genre: string
  archived: boolean
}
export type NewCETSubjectVO = {
  directions: string
  image: string
  ideas: string[]
  keywords: string
  year: number
  genre: string
  month: number
  set: number
  points: number
  subtitle: string
  archived: boolean
}
export type NewIDVO = {
  _id: string
}
export type SubjectVO = NewSubjectVO & NewIDVO
export type CETSubjectVO = NewCETSubjectVO & NewIDVO

export enum SubjectGenre {
  GRADUATE = 'graduate',
  CET4 = 'cet4',
  CET6 = 'cet6',
}
const cName = (genre: string) =>
  genre === SubjectGenre.GRADUATE ? COLLECTION_NAME : CET_COLLECTION_NAME
export const getCount = async (
  genre: string,
  condition: any | null,
): Promise<number> => {
  const collectionName = cName(genre)
  let result
  if (condition === null) {
    result = await db
      .collection(collectionName)
      .where({ genre: cmd.eq(genre) })
      .count()
    return result.total
  } else {
    const params = packEQParams(condition)
    result = await countObject(collectionName, params)
    return result
  }
}
export const createSubject = async (
  newSubject: NewSubjectVO | NewCETSubjectVO,
): Promise<boolean> => {
  return new Promise((resolve) => {
    let collection
    if (newSubject.genre === SubjectGenre.GRADUATE) {
      collection = db.collection(COLLECTION_NAME)
    } else {
      collection = db.collection(CET_COLLECTION_NAME)
    }

    collection
      .add(newSubject)
      .then((res) => {
        console.log(res)
        resolve(true)
      })
      .catch((err) => {
        console.log(err)
        resolve(false)
      })
  })
}
export const getSubjects = async (
  genre: string,
  page: number,
  limit: number,
): Promise<Array<SubjectVO | CETSubjectVO>> => {
  const collectionName = cName(genre)
  return new Promise((resolve) => {
    db.collection(collectionName)
      .where({
        genre: cmd.eq(genre),
      })
      .skip(page * limit)
      .limit(limit)
      .get()
      .then((res) => {
        const arr = res.data
        const result: Array<SubjectVO | CETSubjectVO> = []
        arr.forEach((element: any) => {
          result.push(parseSubject(genre, element))
        })
        resolve(result)
      })
      .catch((err) => {
        resolve([])
      })
  })
}
export const getSubjectsCondition = mm(
  async (
    genre: string,
    page: number,
    limit: number,
    condition: any,
  ): Promise<Array<SubjectVO | CETSubjectVO>> => {
    return new Promise((resolve) => {
      const collectionName = cName(genre)
      const params = packEQParams(condition)
      params['genre'] = cmd.eq(genre)
      console.log('getSubjectsCondition service', collectionName, params)
      getObjects(collectionName, page, limit, params).then((res) => {
        const arr = res
        const result: Array<SubjectVO | CETSubjectVO> = []
        arr.forEach((element: any) => {
          result.push(parseSubject(genre, element))
        })
        resolve(result)
      })
    })
  },
  {
    promise: true,
    normalizer: function (args) {
      return JSON.stringify(args)
    },
  },
)
export const updateSubject = async (
  newObject: SubjectVO | CETSubjectVO,
  key: string,
  value: any,
): Promise<boolean> => {
  const collectionName = cName(newObject.genre)
  // return Promise.resolve(true)
  return updateObjectById(collectionName, newObject._id!, key, value)
}

export const newEmptyObject = (
  genre: string,
): NewCETSubjectVO | NewSubjectVO => {
  if (genre === SubjectGenre.GRADUATE) {
    return {
      directions: '',
      ideas: [],
      image: '',
      keywords: '',
      points: 10,
      subtitle: '',
      year: 2022,
      genre: SubjectGenre.GRADUATE,
      part: 'A',
      type: '1',
      archived: true,
    }
  }
  return {
    directions: '',
    ideas: [],
    keywords: '',
    year: 2022,
    genre: genre,
    month: 12,
    set: 1,
    points: 106.5,
    subtitle: '',
    archived: true,
    image: ''
  }
}

const parseSubject = (
  genre: string,
  element: any,
): SubjectVO | CETSubjectVO => {
  return genre === SubjectGenre.GRADUATE
    ? parseGraduateSubject(element)
    : parseCETSubject(element)
}
const parseGraduateSubject = (element: any): SubjectVO => {
  return {
    _id: String(element._id),
    directions: String(element.directions),
    ideas: element.ideas as string[],
    image: String(element.image),
    keywords: String(element.keywords),
    part: element.part,
    type: element.type,
    points: Number(element.points),
    subtitle: String(element.subtitle),
    year: Number(element.year),
    genre: String(element.genre),
    archived: Boolean(element.archived),
  }
}

const parseCETSubject = (element: any): CETSubjectVO => {
  // console.log('parseCETSubject', element);
  return {
    _id: String(element._id),
    directions: String(element.directions),
    image: element.image === undefined ? '' : String(element.image),
    ideas: element.ideas as string[],
    keywords: String(element.keywords),
    month: Number(element.month),
    set: Number(element.set),
    points: Number(element.points),
    subtitle: String(element.subtitle),
    year: Number(element.year),
    genre: String(element.genre),
    archived: Boolean(element.archived),
  }
}


