import { Box } from '@fower/react'
import { FC, useState } from 'react'
import { MatrixSubjectVO } from '../../services/matrix.service'
import MatrixNodes from './components/MatrixNodes'
import MatrixSubjects from './components/MatrixSubjects'

const Matrix: FC = () => {
  const [editMode, setEditMode] = useState<'subject' | 'node'>('subject')
  const [selectedSubject, setSelectedSubject] = useState<
    MatrixSubjectVO | undefined
  >()
  return (
    <Box w="100%" h="100vh" column toCenter>
      {editMode === 'subject' ? (
        <MatrixSubjects onAddNode={(data)=>{
          setSelectedSubject(data)
          setEditMode('node')
        }}/>
      ) : (
        <MatrixNodes subject={selectedSubject} onBack={()=>{
          setEditMode('subject')
        }}/>
      )}
    </Box>
  )
}
export default Matrix
