import { Box } from '@fower/react'
import { useMount } from 'ahooks'
import { FC } from 'react'
import { useDebatebaseStore } from '../../store/debatebase.store'
import DebateBaseItem from './DebateBaseItem'

const DebateBase: FC = () => {
  const { debatebases, setDebatebases, getDebatebases } = useDebatebaseStore()
  useMount(async () => {
    const dbs = await getDebatebases(0, 100)
    setDebatebases(dbs)
  })
  return (
    <Box h="100vh" w="100%" toCenterX column pt32 spaceY2>
      {debatebases.map((item) => {
        return (
          <DebateBaseItem
            data={item}
            key={item._id}
            onUpdate={() => {}}
          />
        )
      })}
    </Box>
  )
}
export default DebateBase
