import { Box } from '@fower/react'
import { Button } from '@mui/material'
import { FC } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '../../../services/auth'

const Nav: FC = () => {
  const auth = useAuth()
  const navigate = useNavigate()
  const lc = useLocation()
  const buttons = [
    {
      path: '/',
      name: '考研题目',
    },
    {
      path: '/cet',
      name: '四六级题目',
    },
    {
      path: '/modelessays',
      name: '范文',
    },
    {
      path: '/debatebase',
      name: 'DebateBase',
    },
    {
      path: '/writings',
      name: '写作记录',
    },
    {
      path: '/phrase',
      name: '例句',
    },
    {
      path: '/matrix',
      name: 'Matrix',
    },
  ]
  return (
    <Box toCenter>
      <Box
        w="80%"
        bgBlack
        h14
        zIndex={100}
        fixed
        top0
        toBetween
        toCenterY
        p2
        shadowGigantic
        rounded
      >
        <Box spaceX5>
          {buttons.map((b) => {
            return (
              <Button
                sx={{
                  color: lc.pathname === b.path ? 'white' : 'primary',
                }}
                variant="text"
                onClick={() => {
                  navigate(b.path)
                }}
                key={b.name}
              >
                {b.name}
              </Button>
            )
          })}
        </Box>
        <Box row toCenterY spaceX2>
          <Box white>{auth.currentUser?.email}</Box>
          <Button
            variant="contained"
            size="small"
            onClick={async () => {
              await auth.signOut()
              global.location.reload()
            }}
          >
            logout
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default Nav
