import create from 'zustand'
import {
  AnnotationVO,
  createAnnotation,
  deleteAnnotationByID,
  getAnnotationsBywrID,
  updateAnnotationProps
} from '../services/annotation.service'
import {
  getWritings,
  updateWritingProps,
  WritingVO,
  deleteWritingByID,
  getWritingsWithCondition
} from '../services/writings.service'

export type TextDebrisVO = {
  index: number
  text: string
  annotation?: AnnotationVO
  tagType: string
}

interface WritingState {
  writings: WritingVO[]
  annotations: AnnotationVO[]
  setWritings: (value: WritingVO[]) => void
  getWritings: (
    page: number,
    limit: number,
    clearCache?: boolean,
  ) => Promise<WritingVO[]>
  getWritingsWithCondition: (
    page: number,
    limit: number,
    condition: any
  ) => Promise<WritingVO[]>
  updateWriting: (vo: WritingVO, params: Map<string, any>) => Promise<boolean>
  deleteWriting: (id: string) => Promise<boolean>
  getAnnotationsBywrID: (wrID: string) => Promise<AnnotationVO[]>
  breakTextToDebris(rawText: string): TextDebrisVO[]
  sortAnnotations(): void
  createAnnotation(data: AnnotationVO): Promise<AnnotationVO | null>
  getAnnotationInRange(start: number, end: number): AnnotationVO | undefined
  deleteAnnotationByID(id: string): Promise<boolean>
  updateAnnotationProps(
    annotation: AnnotationVO,
    param: Map<string, any>,
  ): Promise<boolean>
}
export const useWritingsStore = create<WritingState>((set, get) => ({
  writings: [],
  annotations: [],
  setWritings: (value: WritingVO[]) => {
    set({
      writings: value,
    })
  },
  getWritings: async (
    page: number,
    limit: number,
    clearCache?: boolean,
  ): Promise<WritingVO[]> => {
    if (clearCache) {
      getWritings.clear()
    }
    const result = await getWritings(page, limit)
    return result
  },
  getWritingsWithCondition: async (
    page: number,
    limit: number,
    condition: any    
  ): Promise<WritingVO[]> => {
    const result = await getWritingsWithCondition(page, limit, condition)
    return result
  },
  updateWriting: async (
    vo: WritingVO,
    params: Map<string, any>,
  ): Promise<boolean> => {
    const result = await updateWritingProps(vo, params)
    return result
  },
  getAnnotationsBywrID: async (wrID: string): Promise<AnnotationVO[]> => {
    const result = await getAnnotationsBywrID(wrID)
    set({ annotations: result })
    get().sortAnnotations()
    return result
  },
  breakTextToDebris(rawText: string): TextDebrisVO[] {
    const list: TextDebrisVO[] = []
    const aList: TextDebrisVO[] = []
    let endTo = 0
    const annotations = get().annotations
    annotations.forEach((annotation, index) => {
      let lastText = ''
      if (index === 0 || endTo !== annotation.start) {
        lastText = rawText.substring(endTo, annotation.start)
        aList.push({
          index: list.length,
          text: lastText,
          tagType: 'span',
        })
      }
      lastText = rawText.substring(annotation.start, annotation.end)
      aList.push({
        index: aList.length,
        text: lastText,
        annotation,
        tagType: 'span',
      })
      if (
        index === annotations.length - 1 &&
        rawText.length !== annotation.end
      ) {
        lastText = rawText.substring(annotation.end)
        aList.push({
          index: list.length,
          text: lastText,
          tagType: 'span',
        })
      }
      endTo = annotation.end
    })

    aList.forEach((a) => {
      const paragraph = a.text.split('\n')
      paragraph.forEach((p, i) => {
        list.push({
          index: list.length,
          text: p,
          tagType: a.tagType,
          annotation: a.annotation,
        })
        if (i !== paragraph.length - 1) {
          list.push({
            index: list.length,
            text: '',
            tagType: 'br',
          })
        }
      })
    })
    return list
  },
  sortAnnotations(): void {
    const annotations = get().annotations
    const sorted = annotations.sort((a, b) => {
      if (a.start < b.start) {
        return -1
      }
      if (a.start > b.start) {
        return 1
      }
      return 0
    })
    set({ annotations: sorted })
  },
  createAnnotation(data: AnnotationVO): Promise<AnnotationVO | null> {
    return new Promise((resolve) => {
      createAnnotation({
        start: data.start,
        end: data.end,
        comment: data.comment,
        wrID: data.wrID,
        rawContent: data.rawContent,
        correctedContent: data.correctedContent,
        type: data.type,
      })
        .then((newVO) => {
          if (newVO) {
            set({ annotations: [...get().annotations, newVO] })
          }
          get().sortAnnotations()
          resolve(newVO)
        })
        .catch((err) => {
          resolve(null)
        })
    })
  },
  getAnnotationInRange(start: number, end: number): AnnotationVO | undefined {
    const annotations = get().annotations
    const result = annotations.find((a) => {
      return a.start <= start && a.end >= end
    })
    return result
  },
  deleteAnnotationByID(id: string): Promise<boolean> {
    return new Promise((resolve) => {
      deleteAnnotationByID(id)
        .then((result) => {
          if (result) {
            set({ annotations: get().annotations.filter((a) => a._id !== id) })
          }
          resolve(result)
        })
        .catch((err) => {
          resolve(false)
        })
    })
  },
  updateAnnotationProps(
    annotation: AnnotationVO,
    param: Map<string, any>,
  ): Promise<boolean> {
    console.log('updateAnnotationProps', param)
    return new Promise((resolve) => {
      updateAnnotationProps(annotation, param)
        .then((result) => {
          if (result) {
            const annotations = get().annotations
            const index = annotations.findIndex((a) => a._id === annotation._id)
            if (index !== -1) {
              const target = {
                ...annotations[index],
                ...Object.fromEntries(param),
              }
              console.log('updateAnnotationProps after', target)
              annotations[index] = target
              set({ annotations })
            }
          }
          resolve(result)
        })
        .catch((err) => {
          resolve(false)
        })
    })
  },
  deleteWriting: (id: string):Promise<boolean> => {
    return new Promise((resolve) => {
      deleteWritingByID(id)
        .then((result) => {
          if (result) {
            set({ writings: get().writings.filter((w) => w._id !== id) })
          }
          resolve(result)
        })
        .catch((err) => {
          resolve(false)
        })
    })
  },
}))
