import { Box } from '@fower/react';
import { Button, Input } from '@mui/material';
import { FC, useState } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export type OptionItemProps = {
  index: number;
  text: string;
  onChange: (index: number, newValue: string) => void;
  onDelete: (index: number) => void;
};

const OptionItem: FC<OptionItemProps> = ({
  text,
  index,
  onChange,
  onDelete,
}) => {
  return (
    <Box w='100%' row toCenterY toBetween>
      <Input
        type='text'
        value={text}
        onChange={(event) => {
          onChange(index, event.target.value);
        }}
        sx={{
          width: '80%',
        }}
      />
      <Box
        onClick={() => {
          onDelete(index);
        }}
      >
        <DeleteForeverIcon fontSize='small' color='warning' />
      </Box>
    </Box>
  );
};

export type EditableStringArrayProps = {
  label: string;
  options: Array<string>;
  onUpdate: (newValue: Array<string>) => void;
};

const EditableStringArray: FC<EditableStringArrayProps> = ({
  options,
  onUpdate,
  label,
}) => {
  const [editingArray, setEditingArray] = useState<Array<string>>(options);
  return (
    <Box column spaceY2 border='solid 1px' rounded p5>
      <Box row toCenterY toBetween>
        <Box fontExtrabold mr2 black>
          {label}
        </Box>
        <Box spaceX2>
          <Button variant='contained' size='small' onClick={() => {
            const newArray = [...editingArray, ''];
            setEditingArray(newArray);
          }}>
            add
          </Button>
          <Button variant='contained' size='small' onClick={() => {
            onUpdate(editingArray);
          }}>
            update
          </Button>
          <Button
            variant='contained'
            size='small'
            onClick={() => {
              setEditingArray(options);
            }}
          >
            reset
          </Button>
        </Box>
      </Box>
      {editingArray.map((option, index) => {
        return (
          <OptionItem
            text={option}
            index={index}
            onChange={(index, newValue) => {
              const newArray = [...editingArray];
              newArray[index] = newValue;
              setEditingArray(newArray);
            }}
            onDelete={(index) => {
              const newArray = [...editingArray];
              newArray.splice(index, 1);
              setEditingArray(newArray);
            }}
            key={String(index)}
          />
        );
      })}
    </Box>
  );
};
export default EditableStringArray;
