import { getApp, getDB, getUser } from './auth'
import { updateObjectPropsById } from './common.service'
import mm from 'memoizee'
const db = getDB()
const app = getApp()
const user = getUser()
const cmd = db.command
const COLLECTION_NAME: string = 'debatebase'
export type DebatebaseVO = {
  _id: string
  archived: boolean
  genre: string
  content: string
  title: string
  pointsAgainst: string[]
  pointsFor: string[]
  subjectId?: string
  wrId?: string
}

const parseVO = (data: any): DebatebaseVO => {
  return {
    _id: data._id,
    archived: data.archived,
    genre: data.genre,
    content: data.content,
    title: data.title,
    pointsAgainst: data.pointsAgainst,
    pointsFor: data.pointsFor,
    subjectId: data.subjectId,
    wrId: data.wrId,
  }
}

export const getDebatebases = mm(
  (page: number, limit: number): Promise<DebatebaseVO[]> => {
    return new Promise((resolve) => {
      db.collection(COLLECTION_NAME)
        .where({
          archived: cmd.eq(false),
        })
        .skip(page * limit)
        .limit(limit)
        .get()
        .then((result) => {
          resolve(
            result.data.map((data) => {
              return parseVO(data)
            }),
          )
        })
    })
  },
  {
    promise: true,
  },
)

export const updateDebatebase = (
  vo: DebatebaseVO,
  params: Map<string, any>,
) => {
  return updateObjectPropsById(COLLECTION_NAME, vo._id!, params)
}

export async function evaluateDebate(vo: DebatebaseVO): Promise<string> {
  const params = {
    type: 6,
    openId: user?.uid,
    wordsCount: vo.content.split(' ').length,
    timeUsed: 800,
    dbId: vo._id,
  }
  return new Promise((resolve) => {
    app
      .callFunction({
        name: 'writing_test',
        data: params,
      })
      .then((res) => {
        const resultObj = res as any
        console.log(resultObj)
        resolve(resultObj.result.data.id || resultObj.result.data._id)
      })
  })
}
