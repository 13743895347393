import { Box } from '@fower/react'
import { Button, Dialog, DialogContent } from '@mui/material'
import { FC, useState } from 'react'
import EditableSelection from '../../components/EditableSelection'
import {
  getDescForGenre,
  phraseGenre2Range,
  phraseGenre3Range,
  sentenceRange,
  paragraphRange,
} from './PhrasePreset'
import RelatedToSubject from '../../components/RelatedToSubject'
import { PhraseVO, PhraseGenre } from '../../services/phrase.service'
import {
  CETSubjectVO,
  SubjectGenre,
  SubjectVO,
} from '../../services/subjects.service'
import { usePhraseStore } from '../../store/phrase.store'
import EditableTextArea from '../../components/EditableTextArea'
export type CreatePhraseModalProps = {
  open: boolean
  onClose: () => void
  genre: string
  subjects: Array<SubjectVO | CETSubjectVO>
}
const CreatePhraseModal: FC<CreatePhraseModalProps> = ({
  open,
  onClose,
  genre,
  subjects,
}) => {
  const { addPhrase } = usePhraseStore()
  const [toSubjectId, setToSubjectId] = useState<string>('')
  const [genre2, setGenre2] = useState<PhraseGenre>('chart')
  const [genre3, setGenre3] = useState<string>('1')
  const [paragraph, setParagraph] = useState<string>('1')
  const [sentence, setSentence] = useState<string>('1')
  const [content, setContent] = useState<string>('')
  const [translation, setTranslation] = useState<string>('')
  const [functionality, setFunctionality] = useState<string>('')
  const reset = () => {
    setContent('')
    setTranslation('')
    setFunctionality('')
  }
  const hasInput = () => {
    if (content !== '') return true
    if (translation !== '') return true
    if (functionality !== '') return true
    return false
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Box toCenter>
          <Box column w="840px" spaceY2>
            <RelatedToSubject
              genre={genre}
              selectedSubjectId={toSubjectId}
              subjects={subjects}
              onChange={(subject) => {
                subject && setToSubjectId(subject._id)
              }}
            />
            <Box row spaceX5 toCenterY>
              <EditableSelection
                label="分类2"
                selectedValue={getDescForGenre(genre2, phraseGenre2Range)}
                options={phraseGenre2Range.map((o) => o.id)}
                optionData={phraseGenre2Range}
                onChange={(newValue) => {
                  setGenre2(newValue)
                }}
                hasReset={false}
              />
              <EditableSelection
                label="分类3"
                selectedValue={getDescForGenre(genre3, phraseGenre3Range)}
                options={phraseGenre3Range.map((o) => o.id)}
                optionData={phraseGenre3Range}
                onChange={(newValue) => {
                  console.log(newValue)
                  setGenre3(newValue)
                }}
                hasReset={false}
              />
              <EditableSelection
                label="段"
                selectedValue={paragraph}
                options={paragraphRange}
                onChange={(newValue) => {
                  setParagraph(newValue)
                }}
                hasReset={false}
              />
              <EditableSelection
                label="句"
                selectedValue={sentence}
                options={sentenceRange}
                onChange={(newValue) => {
                  setSentence(newValue)
                }}
                hasReset={false}
              />
            </Box>

            <EditableTextArea
              label="内容"
              text={content}
              onUpdate={(newValue) => {
                setContent(newValue)
              }}
              immediateUpdate={true}
            />

            <EditableTextArea
              label="译文"
              text={translation}
              onUpdate={(newValue) => {
                setTranslation(newValue)
              }}
              immediateUpdate={true}
            />

            <EditableTextArea
              label="作用"
              text={functionality}
              onUpdate={(newValue) => {
                setFunctionality(newValue)
              }}
              immediateUpdate={true}
            />
            <Box row spaceX2 mt2 toRight>
              <Button
                variant="contained"
                onClick={() => {
                  if (hasInput()) {
                    const result = global.confirm('您已经在页面上输入了内容，就这么关了没关系么？')
                    if (result) {
                      onClose()
                    }
                  } else {
                    onClose()
                  }
                }}
              >
                取消
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  const newPhrase: PhraseVO = {
                    genre1: genre as SubjectGenre,
                    genre2,
                    genre3,
                    paragraph: Number(paragraph),
                    sentence: Number(sentence),
                    content,
                    translation,
                    functionality,
                    subjectId: toSubjectId,
                  }
                  addPhrase(newPhrase).then((success) => {
                    if (success) {
                      reset()
                      onClose()
                    }
                  })
                }}
              >
                创建
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
export default CreatePhraseModal
