import { getApp, getDB } from './auth'
import mm from 'memoizee'
import { packEQParams, updateObjectPropsById } from './common.service'
const db = getDB()
const app = getApp()
const cmd = db.command
const COLLECTION_NAME = 'writing-record'

export type WritingVO = {
  _id: string
  createDate: number
  fileID: string
  qId: string
  score: number
  totalScore: number
  subjectId: string
  author: string
  status: string
}

const parseWriting = (element: any): WritingVO => {
  return {
    _id: element._id,
    createDate: element.createDate,
    fileID: element.fileID,
    qId: element.qId,
    score: element.score,
    totalScore: element.totalScore,
    subjectId: element.subjectId,
    author: element.author || 'unknown',
    status: element.status || 'virgin',
  }
}
export const getWritings = mm(
  async (page: number, limit: number): Promise<Array<WritingVO>> => {
    return new Promise((resolve) => {
      db.collection(COLLECTION_NAME)
        .where({
          subjectId: cmd.neq('debatebase'),
        })
        .orderBy('createDate', 'desc')
        .skip(page * limit)
        .limit(limit)
        .get()
        .then((res) => {
          const arr = res.data
          const result: Array<WritingVO> = []
          arr.forEach((element: any) => {
            result.push(parseWriting(element))
          })
          resolve(result)
        })
        .catch((err) => {
          resolve([])
        })
    })
  },
  { promise: true },
)
export const getWritingsWithCondition = mm(
  async (
    page: number,
    limit: number,
    condition: any,
  ): Promise<Array<WritingVO>> => {
    const params = packEQParams(condition)
    return new Promise((resolve) => {
      db.collection(COLLECTION_NAME)
        .where({
          ...params,
          subjectId: cmd.neq('debatebase'),
        })
        .skip(page * limit)
        .limit(limit)
        .orderBy('createDate', 'desc')
        .get()
        .then((res) => {
          const arr = res.data
          const result: Array<WritingVO> = []
          arr.forEach((element: any) => {
            result.push(parseWriting(element))
          })
          console.log(arr);
          resolve(result)
        })
        .catch((err) => {
          resolve([])
        })
    })
  },
  { promise: true },
)
export const loadResultFile = mm(
  async (fileID: string): Promise<any> => {
    return new Promise((resolve) => {
      app
        .callFunction({
          name: 'manage_writing_result',
          data: { action: 'getResultRaw', fileID },
        })
        .then((res) => {
          resolve(res.result.data)
        })
    })
  },
  {
    promise: true,
  },
)

export const updateWritingProps = async (
  newObject: WritingVO,
  params: Map<string, any>,
): Promise<boolean> => {
  return updateObjectPropsById(COLLECTION_NAME, newObject._id!, params)
}

export const deleteWritingByID = async (id: string): Promise<boolean> => {
  try {
    await db.collection(COLLECTION_NAME).doc(id).remove()
    return true
  } catch (error) {
    return false
  }
}

export const addAuthorDebatebase = async ():Promise<boolean> =>{
  return new Promise((resolve)=>{
    db.collection(COLLECTION_NAME)
        .where({
          author: cmd.eq('modelessay'),
        }).update({
          status: 'virgin',
        }).then(res=>{
          console.log(res);          
          resolve(true)
        }).catch(()=>{
          resolve(false)
        })
  })
  
}
