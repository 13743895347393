import { Box } from '@fower/react';
import { Button, Dialog, DialogContent } from '@mui/material';
import { useMount } from 'ahooks';
import { FC, useState } from 'react';
import EditableTextArea from '../../components/EditableTextArea';
import { CETSubjectVO, SubjectVO } from '../../services/subjects.service';
import { useModelEssayStore } from '../../store/model-essay.store';
import RelatedToSubject from '../../components/RelatedToSubject';

export type CreateModelEssayProps = {
  genre:string,
  open: boolean;
  onClose: (updated: boolean) => void;
  subjects: Array<SubjectVO|CETSubjectVO>;
};

const CreateModelEssay: FC<CreateModelEssayProps> = ({
  open,
  genre,
  onClose,
  subjects,
}) => {
  const { createModelEssay } = useModelEssayStore();
  const [text, setText] = useState<string>('');
  const [toSubjectId, setToSubjectId] = useState<string>('');
  useMount(async () => {
    if (subjects[0]) {
      setToSubjectId(subjects[0]._id);
    }
  });
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box column w='500px'>
          <EditableTextArea
            label='text'
            text={text}
            onUpdate={(value) => {
              setText(value);
            }}
            immediateUpdate={true}
          ></EditableTextArea>
          <RelatedToSubject
            genre={genre}
            selectedSubjectId={toSubjectId}
            subjects={subjects}
            onChange={(subject) => {
              subject && setToSubjectId(subject._id);
            }}
          />
          <Box row spaceX2 mt2>
            <Button
              variant='contained'
              color='warning'
              onClick={() => {
                onClose(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                createModelEssay({
                  text,
                  subjectId: toSubjectId,
                  genre
                }).then((_newVO) => {
                  setText('');
                  onClose(true);
                });
              }}
            >
              Create
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default CreateModelEssay;
