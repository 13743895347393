import { getDB } from './auth'
import { SubjectGenre } from './subjects.service'
import mm from 'memoizee'
import { countObject, packEQParams } from './common.service'
export type PhraseGenre = 'text' | 'picture' | 'chart'

export type PhraseVO = {
  _id?: string
  _openid?: string
  genre1: SubjectGenre
  genre2: PhraseGenre
  genre3: string
  paragraph: number
  sentence: number
  content: string
  translation: string
  functionality: string
  subjectId: string
}

const db = getDB()
const COLLECTION_NAME: string = 'phrase'

export const getCount = async (condition: any | null): Promise<number> => {
  let result
  if (condition === null) {
    result = await db.collection(COLLECTION_NAME).count()
    return result.total
  } else {
    const params = packEQParams(condition)
    result = await countObject(COLLECTION_NAME, params)
    return result
  }
}

export const getPhrases = mm(
  async (page: number, limit: number): Promise<Array<PhraseVO>> => {
    return new Promise((resolve) => {
      db.collection(COLLECTION_NAME)
        .where({})
        .skip(page * limit)
        .limit(limit)
        .get()
        .then((res) => {
          const arr = res.data
          const result: Array<PhraseVO> = arr as Array<PhraseVO>
          resolve(result)
        })
    })
  },
  {
    promise: true,
    normalizer: function (args) {
      return JSON.stringify(args)
    },
  },
)

export const getPhrasesWithContion = mm(
  async (
    condition: any | null,
    page: number,
    limit: number,
  ): Promise<Array<PhraseVO>> => {
    if (condition === null) {
      return getPhrases(page, limit)
    }
    return new Promise((resolve) => {
      const params = packEQParams(condition)
      db.collection(COLLECTION_NAME)
        .where(params)
        .skip(page * limit)
        .limit(limit)
        .get()
        .then((res) => {
          const arr = res.data
          const result: Array<PhraseVO> = arr as Array<PhraseVO>
          resolve(result)
        })
    })
  },
  {
    promise: true,
  },
)

export const addPhrase = async (
  newPhrase: PhraseVO,
): Promise<{ success: boolean; id?: string }> => {
  return new Promise((resolve) => {
    db.collection(COLLECTION_NAME)
      .add(newPhrase)
      .then((res: any) => {
        resolve({ success: true, id: res.id })
      })
      .catch((err) => {
        console.log(err)
        resolve({ success: false })
      })
  })
}

export const deletePhrase = async (id: string): Promise<boolean> => {
  return new Promise((resolve) => {
    db.collection(COLLECTION_NAME)
      .doc(id)
      .remove()
      .then(() => {
        resolve(true)
      })
      .catch((err) => {
        console.log(err)
        resolve(false)
      })
  })
}
export const updatePhrase = async (
  id: string,
  updatedVO: PhraseVO,
): Promise<boolean> => {
  return new Promise((resolve) => {
    db.collection(COLLECTION_NAME)
      .doc(id)
      .update(updatedVO)
      .then((res) => {
        resolve(true)
      })
      .catch((err) => {
        console.log(err)
        resolve(false)
      })
  })
}
