import { Box } from '@fower/react';
import { FC, useState } from 'react';
import EditableSelection from '../../components/EditableSelection';
import EditableText from '../../components/EditableText';
import EditableTextArea from '../../components/EditableTextArea';
import ItemActionBar from '../../components/ItemActionBar';
import { DebatebaseVO } from '../../services/debatebase.service';
import { useDebatebaseStore } from '../../store/debatebase.store';
import CopyBar from '../home/components/CopyBar';
import EVAActionBar from '../modelessays/EVAActionBar';
import { debateBaseGenresRange } from '../../components/RangePreset';

export type DebateBaseItemProps = {
  data: DebatebaseVO;
  onUpdate: (data: DebatebaseVO) => void;
};
const DebateBaseItem: FC<DebateBaseItemProps> = ({ data }) => {
  const { evaluateDebate, updateDebatebase } = useDebatebaseStore();
  const [editingVO, setEditingVO] = useState<DebatebaseVO>(data);
  return (
    <Box column bgWhite rounded p2 w='80%' spaceY2>
      <CopyBar text={editingVO._id} />
      <EditableSelection
        label='Genre'
        selectedValue={editingVO.genre}
        options={debateBaseGenresRange}
        onChange={(newValue) => {
          editingVO.genre = newValue;
        }}
      />
      <EditableText
        label='Title'
        text={editingVO.title}
        onUpdate={(newValue) => {
          editingVO.title = newValue;
          updateDebatebase(editingVO).then((success) => {
            if (success) {
              setEditingVO(editingVO);
            }
          });
        }}
      />
      <EditableTextArea
        label='Content'
        text={editingVO.content}
        onUpdate={(newValue) => {
          editingVO.content = newValue;
          updateDebatebase(editingVO).then((success) => {
            if (success) {
              setEditingVO(editingVO);
            }
          });
        }}
      />
      
      <ItemActionBar
        availableActions={['update']}
        customizedComponents={
          <EVAActionBar
            wrId={editingVO.wrId}
            onAction={() => {
              evaluateDebate(editingVO).then((wrId) => {
                editingVO.wrId = wrId;
                updateDebatebase(editingVO).then((success) => {
                  if (success) {
                    setEditingVO({ ...editingVO, wrId });
                  }
                });
              });
            }}
          />
        }
        onUpdate={() => {
          updateDebatebase(editingVO).then((success) => {
            if (success) {
              setEditingVO({ ...editingVO });
            }
          });
        }}
        onReset={() => {
          setEditingVO({
            ...data,
          });
        }}
      />
    </Box>
  );
};
export default DebateBaseItem;
