import { getApp, getDB, getUser } from './auth'
import { updateObjectPropsById } from './common.service'
import mm from 'memoizee'
const db = getDB()
const app = getApp()
const user = getUser()
const cmd = db.command
const COLLECTION_NAME: string = 'model-essay'
export type ModelEssayVO = {
  _id?: string
  text: string
  genre: string
  createdDate?: number
  _openid?: string
  wrId?: string
  subjectId?: string
}

const parseVO = (data: any): ModelEssayVO => {
  return {
    _id: data._id,
    text: data.text,
    createdDate: data.createdDate,
    _openid: data._openid,
    wrId: data.wrId,
    subjectId: data.subjectId,
    genre: data.genre
  }
}

export const createME = (data: ModelEssayVO): Promise<ModelEssayVO | null> => {
  return new Promise((resolve) => {
    const createdDate: number = Date.now()
    db.collection(COLLECTION_NAME)
      .add({
        ...data,
        createdDate,
        author: 'modelessay',
      })
      .then((result) => {
        const resultData = result as any
        resolve({
          ...data,
          _id: resultData['id'],
        })
      })
      .catch((error) => {
        console.error(error)
        resolve(null)
      })
  })
}

export const getMEs = mm(
  (genre: string, page: number, limit: number): Promise<ModelEssayVO[]> => {
    return new Promise((resolve) => {
      db.collection(COLLECTION_NAME)
        .where({
          genre: cmd.eq(genre),
        })
        .orderBy('createdDate', 'desc')
        .skip(page * limit)
        .limit(limit)
        .get()
        .then((result) => {
          resolve(
            result.data.map((data) => {
              return parseVO(data)
            }),
          )
        })
    })
  },
  {
    promise: true,
  },
)

export const getMEsBySubjectID = mm(
  (subjectId: string, page: number, limit: number): Promise<ModelEssayVO[]> => {
    return new Promise((resolve) => {
      db.collection(COLLECTION_NAME)
        .where({
          subjectId: cmd.eq(subjectId),
        })
        .orderBy('createdDate', 'desc')
        .skip(page * limit)
        .limit(limit)
        .get()
        .then((result) => {
          resolve(
            result.data.map((data) => {
              return parseVO(data)
            }),
          )
        })
    })
  },
  {
    promise: true,
  },
)

export async function deleteME(newObject: ModelEssayVO): Promise<boolean> {
  try {
    await db.collection(COLLECTION_NAME).doc(newObject._id!).remove()
    return true
  } catch (error) {
    return false
  }
}
export async function updateMEProps(
  newObject: ModelEssayVO,
  params: Map<string, any>,
): Promise<boolean> {
  return updateObjectPropsById(COLLECTION_NAME, newObject._id!, params)
}

export async function evaluateEssay(vo: ModelEssayVO): Promise<string> {
  const params = {
    type: 5,
    subjectId: vo.subjectId!,
    openId: user?.uid,
    wordsCount: vo.text.split(' ').length,
    timeUsed: 800,
    meId: vo._id,
    grade: vo.genre
  }
  return new Promise((resolve) => {
    app
      .callFunction({
        name: 'writing_test',
        data: params,
      })
      .then((res) => {
        const resultObj = res as any
        console.log(resultObj)
        resolve(resultObj.result.data.id || resultObj.result.data._id)
      })
  })
}

export async function getCount(genre:string, subjectId: string | null): Promise<number> {
  return new Promise((resolve) => {
    if (subjectId === null) {
      db.collection(COLLECTION_NAME).where({genre: cmd.eq(genre)})
        .count()
        .then((res) => {
          resolve(res.total)
        })
    } else {
      db.collection(COLLECTION_NAME)
        .where({
          subjectId: cmd.eq(subjectId),
          genre: cmd.eq(genre)
        })
        .count()
        .then((res) => {
          resolve(res.total)
        })
    }
  })
}
