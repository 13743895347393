import create from 'zustand'
import {
  createMatrixNode,
  scanSubNodes,
  deleteNodeFromOption,
  getMatrixNodes,
  MatrixNodeVO,
  MatrixOptionNodeVO,
  updateNodeByID,
  deleteNodes,
  getNodeByName,
} from '../services/matrix.service'
import { v4 as uuidv4 } from 'uuid'
import produce from 'immer'
interface MatrixNodeState {
  nodes: Array<MatrixNodeVO>
  newNode: MatrixNodeVO | null
  newOption: MatrixOptionNodeVO | null
  initNewNode: (
    subjectId: string,
    isRoot: boolean,
    parentNode?: string,
    fromOption?: string,
  ) => void
  initNewOption: (subjectId: string, parentNode: string) => void
  createNewNode: (value: MatrixNodeVO) => Promise<MatrixNodeVO>
  updateNode: (value: MatrixNodeVO,key:string, data:any) => Promise<boolean>
  getNodeByID: (nodeId: string) => Promise<MatrixNodeVO | undefined>
  getNodes: (subjectId: string) => Promise<Array<MatrixNodeVO>>
  setNodes: (newNodes: MatrixNodeVO[]) => void
  setCacheNode: (node: MatrixNodeVO) => void
  scanSubNode: (nodeToDelete:MatrixNodeVO) => MatrixNodeVO[]
  deleteNodes: (nodesAndDecendents:string[]) => Promise<boolean>
  addOptionTo: (
    option: MatrixOptionNodeVO,
    toNode: MatrixNodeVO,
  ) => Promise<MatrixOptionNodeVO>
  deleteOptionFrom: (
    option: MatrixOptionNodeVO,
    fromNode: MatrixNodeVO,
  ) => Promise<MatrixNodeVO>
  updateOption: (
    option: MatrixOptionNodeVO,
    toNode: MatrixNodeVO,
  ) => Promise<MatrixOptionNodeVO>
  findExistingNodeByName:(subjectId:string, name:string) => Promise<MatrixNodeVO[]>
}
export const useMatrixNodeStore = create<MatrixNodeState>((set, get) => ({
  nodes: [],
  newNode: null,
  newOption: null,
  initNewNode(subjectId, isRoot, parentNode?: string, fromOption?: string) {
    set({
      newNode: {
        text: '',
        belongsTo: subjectId,
        options: [],
        isRoot,
        parentNode,
        fromOption,
      },
    })
  },
  initNewOption(subjectId: string, parentNode: string) {
    set({
      newOption: {
        text: '',
        phrase: '',
        translation: '',
        functionality: '',
        belongsToNode: parentNode,
        belongsToSubject: subjectId,
        badChoice: false,
      },
    })
  },
  async getNodes(subjectId: string) {
    const nodes = await getMatrixNodes(subjectId)
    return nodes
  },
  async createNewNode(value) {
    return new Promise((resolve) => {
      createMatrixNode(value).then((newID) => {
        const newNode: MatrixNodeVO = {
          ...value,
          _id: newID,
        }
        set({
          nodes: [...get().nodes, newNode],
        })
        resolve(newNode)
      })
    })
  },
  async updateNode(node, key, data) {
    const params:Map<string,any> = new Map<string, any>()
    params.set(key, data)
    const result = await updateNodeByID(node, params)
    return result
  },
  scanSubNode(nodeToDelete) {
    return scanSubNodes(nodeToDelete, get().nodes)
  },
  async deleteNodes(nodesAndDecendents) {
    await deleteNodes(nodesAndDecendents)
    return Promise.resolve(true)
  },
  async addOptionTo(option, toNode) {
    option._id = uuidv4()
    const params = new Map<string, any>()
    params.set('options', [...toNode.options, option])
    const success = await updateNodeByID(toNode, params)
    if (success) {
      toNode.options.push(option)
    }
    return option
  },
  async deleteOptionFrom(option, fromNode) {
    const newOptions = fromNode.options.filter((o) => o._id !== option._id)
    const params = new Map<string, any>()
    params.set('options', newOptions)
    await updateNodeByID(fromNode, params)
    await deleteNodeFromOption(option)
    set(
      produce((state) => {
        state.nodes = state.nodes.filter(
          (n: any) => n.fromOption !== option._id,
        )
      }),
      true,
    )
    const newNode = { ...fromNode, options: [...newOptions] }
    return newNode
  },
  async updateOption(newOption, toNode) {
    const optionIndex = toNode.options.findIndex(
      (o: any) => o._id === newOption._id,
    )
    let newNode: MatrixNodeVO

    if (optionIndex !== -1) {
      newNode = {
        ...toNode,
        options: [
          ...toNode.options.slice(0, optionIndex),
          newOption,
          ...toNode.options.slice(optionIndex + 1),
        ],
      }
    } else {
      newNode = { ...toNode }
    }
    const params = new Map<string, any>()
    params.set('options', [...newNode.options])
    const success = await updateNodeByID(newNode, params)
    if (success) {
      set(
        produce((state) => {
          const nodeIndex = state.nodes.findIndex(
            (n: any) => n._id === newNode._id,
          )
          if (nodeIndex !== -1) {
            state.nodes = [
              ...state.nodes.slice(0, nodeIndex),
              newNode,
              ...state.nodes.slice(nodeIndex + 1),
            ]
          }
        }),
      )
    }
    return newOption
  },
  getNodeByID(nodeId) {
    return new Promise((resolve) => {
      const target = get().nodes.find((n) => n._id === nodeId)
      resolve(target)
    })
  },
  setNodes(newNodes) {
    set({
      nodes: newNodes,
    })
  },
  setCacheNode(node) {
    set(
      produce((state) => {
        const nodeIndex = state.nodes.findIndex(
          (n: any) => n._id === node._id,
        )
        if (nodeIndex !== -1) {
          state.nodes = [
            ...state.nodes.slice(0, nodeIndex),
            node,
            ...state.nodes.slice(nodeIndex + 1),
          ]
        }
      }),
    )
  },
  findExistingNodeByName(subjectId, name) {
    return getNodeByName(subjectId, name)
  },
}))
