import { FC, useState } from 'react'
import { Box } from '@fower/react'
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material'
import EditableSelection from '../../../components/EditableSelection'
import {
  MatrixGenre,
  NewMatrixSubjectVO,
} from '../../../services/matrix.service'
import { getDescForGenre, phraseGenre2Range } from '../../phrase/PhrasePreset'
import EditableText from '../../../components/EditableText'
import { useMatrixSubjectsStore } from '../../../store/matrix.store'
import { genreRangeData, partRangeData } from '../../../components/RangePreset'
import SpecificTypeSelection from '../../../components/specific-selections/SpecificTypeSelection'

export type CreateMatrixProps = {
  open: boolean
  onClose: () => void
}
const CreateMatrix: FC<CreateMatrixProps> = ({ open, onClose }) => {
  const { createNewSubject } = useMatrixSubjectsStore()

  const [genre, setGenre] = useState<string>('graduate')
  const [genre2, setGenre2] = useState<string>('A')
  const [type, setType] = useState<MatrixGenre>(MatrixGenre.TEXT)
  const [specificType, setSpecificType] = useState<string>('2')
  const [subtitle, setSubtitle] = useState<string>('')
  const [directions, setDirections] = useState<string>('')
  const [image, setImage] = useState<string>('')
  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <DialogTitle>添加一个Matrix题目</DialogTitle>
        <Box toCenter>
          <Box column w="800px" spaceY2>
            <EditableSelection
              label="考试级别"
              selectedValue={genreRangeData.find((p) => p.id === genre)?.name}
              options={genreRangeData.map((o) => o.id)}
              optionData={genreRangeData}
              onChange={(newValue) => {
                setGenre(newValue)
              }}
              hasReset={false}
            />
            <EditableSelection
              label="作文类型"
              selectedValue={partRangeData.find((p) => p.id === genre2)?.name}
              options={partRangeData.map((o) => o.id)}
              optionData={partRangeData}
              onChange={(newValue) => {
                setGenre2(newValue)
              }}
              hasReset={false}
            />
            <EditableSelection
              label="题目分类"
              selectedValue={getDescForGenre(type, phraseGenre2Range)}
              options={phraseGenre2Range.map((o) => o.id)}
              optionData={phraseGenre2Range}
              onChange={(newValue) => {
                setType(newValue)
              }}
              hasReset={false}
            />
            <SpecificTypeSelection
              selectedValue={specificType}
              onUpdate={(newValue) => {
                setSpecificType(newValue)
              }}
            />

            <EditableText
              label="标题"
              text={subtitle}
              onUpdate={(newValue) => {
                setSubtitle(newValue)
              }}
              immediateUpdate={true}
            />
            <EditableText
              label="题面"
              text={directions}
              onUpdate={(newValue) => {
                setDirections(newValue)
              }}
              immediateUpdate={true}
            />
            <EditableText
              label="图片"
              text={image}
              onUpdate={(newValue) => {
                setImage(newValue)
              }}
              immediateUpdate={true}
            />
            <Box row spaceX2 mt2 toRight>
              <Button
                variant="contained"
                onClick={() => {
                  onClose()
                }}
              >
                取消
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  const newRecord: NewMatrixSubjectVO = {
                    type,
                    subtitle,
                    directions,
                    image,
                    genre,
                    genre2,
                    specificType: specificType,
                    archived: false,
                  }
                  createNewSubject(newRecord).then((res) => {
                    onClose()
                  })
                }}
              >
                创建
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
export default CreateMatrix
