import { Box } from '@fower/react'
import { Button } from '@mui/material'
import { useDebounceFn } from 'ahooks'
import { FC, useEffect, useState } from 'react'
import EditableSelection from '../../components/EditableSelection'
import EditableText from '../../components/EditableText'
import EditableTextFlow from '../../components/EditableTextFlow'
import { writingStatusRange } from '../../components/RangePreset'
import { AnnotationVO } from '../../services/annotation.service'
import { CETSubjectVO, SubjectVO } from '../../services/subjects.service'
import { loadResultFile, WritingVO } from '../../services/writings.service'
import { TextDebrisVO, useWritingsStore } from '../../store/writings.store'
import CopyBar from '../home/components/CopyBar'

export type WritingListItemProps = {
  onSelectionChange: (
    wrID: string,
    start: number,
    end: number,
    targetText: string,
  ) => void
  onEditAnnotation: (annotation: AnnotationVO) => void
  data: WritingVO
  subject?: SubjectVO | CETSubjectVO
}
export const WritingListItem: FC<WritingListItemProps> = ({
  data,
  subject,
  onSelectionChange,
  onEditAnnotation,
}) => {
  const {
    updateWriting,
    getAnnotationsBywrID,
    annotations,
    breakTextToDebris,
    getAnnotationInRange,
  } = useWritingsStore()
  const [content, setContent] = useState<string | null>(null)
  const [debrisList, setDebrisList] = useState<TextDebrisVO[]>([])
  useEffect(() => {
    if (!content) return
    setDebrisList(breakTextToDebris(content))
  }, [content, annotations, breakTextToDebris])
  const { run } = useDebounceFn(
    (start: number, end: number, textarea: HTMLTextAreaElement) => {
      if (start === end) {
        const selectedAnnotation = getAnnotationInRange(start, end)
        if (selectedAnnotation) {
          onEditAnnotation(selectedAnnotation)
          textarea.setSelectionRange(0, 0)
        }
      } else {
        onSelectionChange(
          data._id,
          start,
          end,
          content ? content.substring(start, end) : '',
        )
      }
    },
    {
      wait: 200,
    },
  )
  return (
    <Box rounded p5 bgWhite w="80%" column spaceY2>
      <CopyBar text={data._id} />
      <Box black>{data.qId}</Box>
      <Box black>{new Date(data.createDate).toLocaleDateString()}</Box>
      <EditableText
        label="author"
        text={data.author}
        onUpdate={(newValue) => {
          updateWriting(
            data,
            new Map<string, any>().set('author', newValue),
          ).then((success) => {
            if (success) {
              data.author = newValue
            }
          })
        }}
      />
      <EditableSelection
        label="Status"
        selectedValue={data.status}
        options={writingStatusRange}
        onChange={(newStatus) => {}}
      ></EditableSelection>
      <Box black>{subject ? subject.subtitle : '推荐阅读 Debatebase'}</Box>
      <Box row toCenterY spaceX2>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            loadResultFile(data.fileID)
              .then((result) => {
                setContent(result.rawEssay)
              })
              .catch((err) => {
                console.log(err)
              })
            getAnnotationsBywrID(data._id)
          }}
        >
          Load Writing Content
        </Button>
        <Button
          variant="contained"
          color="warning"
          onClick={() => {
            // deleteWriting(data._id).then((success) => {})
          }}
        >
          Delete
        </Button>
      </Box>
      {content && (
        <EditableTextFlow
          label="Content"
          rawText={content}
          debrisList={debrisList}
          onSelect={(start, end, textarea) => {
            run(start, end, textarea)
          }}
        />
      )}
    </Box>
  )
}
