import { getDB } from './auth'
import {
  getObjects,
  packEQParams,
  updateObjectPropsById,
} from './common.service'
import mm from 'memoizee'
const db = getDB()
const COLLECTION_NAME: string = 'annotation'
export type AnnotationVO = {
  _id?: string
  start: number
  end: number
  comment: string
  wrID: string
  rawContent?: string
  correctedContent?: string
  type?: 'correction' | 'suggestion' | 'praise'
}

function parseAnnotation(element: any): AnnotationVO {
  return {
    _id: String(element._id),
    start: element.start,
    end: element.end,
    comment: element.comment,
    wrID: element.wrID,
    rawContent: element.rawContent,
    correctedContent: element.correctedContent,
    type: element.type,
  }
}

export const createAnnotation = (
  data: AnnotationVO,
): Promise<AnnotationVO | null> => {
  return new Promise((resolve) => {
    const createdDate: number = Date.now()
    db.collection(COLLECTION_NAME)
      .add({
        ...data,
        createdDate,
      })
      .then((result) => {
        const resultData = result as any
        db.collection('writing-record')
          .doc(data.wrID)
          .update({ status: 'exuberant' })
          .then(() => {
            resolve({
              ...data,
              _id: resultData['id'],
            })
          })
      })
      .catch((error) => {
        console.error(error)
        resolve(null)
      })
  })
}

export const getAnnotationsBywrID = mm(
  async (wrID: string): Promise<AnnotationVO[]> => {
    return new Promise((resolve) => {
      const params = packEQParams({ wrID })
      getObjects(COLLECTION_NAME, 0, 50, params).then((result) => {
        console.log('getAnnotationsBywrID', result)
        const arr = result.map((element) => parseAnnotation(element))
        resolve(arr)
      })
    })
  },
  { promise: true },
)

export const deleteAnnotationByID = async (id: string): Promise<boolean> => {
  try {
    await db.collection(COLLECTION_NAME).doc(id).remove()
    return true
  } catch (error) {
    return false
  }
}

export const updateAnnotationProps = async (
  annotation: AnnotationVO,
  params: Map<string, any>,
): Promise<boolean> => {
  try {
    await updateObjectPropsById(COLLECTION_NAME, annotation._id!, params)
    return true
  } catch (error) {
    return false
  }
}
