//Phrases
export type OptionVO = {
  id: string
  name: string
}
export const phraseGenre2Range: OptionVO[] = [
  { id: 'text', name: '文字类' },
  { id: 'picture', name: '图画类' },
  { id: 'chart', name: '图表类' },
]
export const phraseGenre3Range: OptionVO[] = [
  { id: '1', name: '谚语' },
  { id: '2', name: '个人观点' },
  { id: '3', name: '利弊对比' },
  { id: '4', name: '意志品质重要性' },
  { id: '5', name: '社会现象分析' },
  { id: '6', name: '解决方案' },
  { id: '7', name: '饼图，比例分布' },
  { id: '8', name: '折线图/柱状图 发展趋势' },
]

export const paragraphRange: string[] = ['1', '2', '3', '4', '5', '6']
export const sentenceRange: string[] = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
]
export const getDescForGenre = (genre: string, fromRange: OptionVO[]) => {
  const target = fromRange.find((g) => {
    return g.id === genre
  })
  if (target) {
    return target.name
  }
  return ''
}
export const getGenreForName = (name: string, fromRange: OptionVO[]) => {
  const target = fromRange.find((g) => {
    return g.name === name
  })
  if (target) {
    return target.id
  }
  return ''
}
