import { Box } from '@fower/react';
import { FC, useState } from 'react';
import { SubjectVO } from '../../../services/subjects.service';
import { useSubjectsStore } from '../../../store/subjects.store';
import EditableText from '../../../components/EditableText';
import styles from './styles.module.css';
import EditableTextArea from '../../../components/EditableTextArea';
import CopyBar from './CopyBar';
import EditableStringArray from '../../../components/EditableStringArray';
import EditableSelection from '../../../components/EditableSelection';
import {
  yearRange,
  typeRange,
  partRange,
  genreRange
} from '../../../components/RangePreset';
import { Button } from '@mui/material';
const SubjectItem: FC<{ data: SubjectVO }> = ({ data }) => {
  
  const { updateSubject, updateWholeSubject } = useSubjectsStore();
  const [year, setYear] = useState<number>(data.year);
  const [type, setType] = useState<string>(data.type);
  const [part, setPart] = useState<string>(data.part);
  const [genre, setGenre] = useState<string>(data.genre);
  return (
    <Box m2 p5 rounded bgWhite column spaceY2>
      <CopyBar text={data._id} />
      <Box row toCenterY spaceX2>
        <EditableSelection
          label='Genre'
          options={genreRange}
          selectedValue={data.genre}
          onChange={(newValue) => {
            setGenre(String(newValue));
          }}
        />
        <EditableSelection
          label='Year'
          options={yearRange}
          selectedValue={data.year}
          onChange={(newValue) => {
            setYear(Number(newValue));
          }}
        />
        <EditableSelection
          label='Type'
          options={typeRange}
          selectedValue={data.type}
          onChange={(newValue) => {
            setType(String(newValue));
          }}
        />
        <EditableSelection
          label='Part'
          options={partRange}
          selectedValue={data.part}
          onChange={(newValue) => {
            setPart(String(newValue));
          }}
        />
        <Box spaceX2>
          <Button
            variant='contained'
            size='small'
            onClick={() => {
              const params = new Map<string, any>();
              params.set('year', year);
              params.set('type', type);
              params.set('part', part);
              params.set('genre', genre);
              updateWholeSubject(data, params);
            }}
          >
            update
          </Button>
        </Box>
      </Box>
      <EditableText
        label={'Subtitle'}
        text={data.subtitle}
        onUpdate={(newValue) => {
          data.subtitle = newValue;
          updateSubject(data, 'subtitle', newValue);
        }}
      />
      <EditableText
        label={'Image'}
        text={data.image}
        onUpdate={(newValue) => {
          data.image = newValue;
          updateSubject(data, 'image', newValue);
        }}
      />
      <EditableText
        label={'Keywords'}
        text={data.keywords}
        onUpdate={(newValue) => {
          data.keywords = newValue;
          updateSubject(data, 'keywords', newValue);
        }}
      />
      <EditableTextArea
        label={'Directions'}
        text={data.directions}
        onUpdate={(newValue) => {
          data.directions = newValue;
          updateSubject(data, 'directions', newValue);
        }}
      />
      <EditableStringArray
        label={'Ideas'}
        options={data.ideas}
        onUpdate={(newValue) => {
          updateSubject(data, 'ideas', newValue).then((success) => {
            data.ideas = newValue;
          });
        }}
      />
      {data.image !== '' && (
        <img
          src={data.image}
          className={styles.previewImage}
          alt='PreviewImage'
        />
      )}
    </Box>
  );
};
export default SubjectItem;
