import { Box } from '@fower/react';
import { Button } from '@mui/material';
import { FC } from 'react';

export type ItemActionBarProps = {
  customizedComponents?: React.ReactNode;
  onUpdate?: () => void;
  onDelete?: () => void;
  onReset?: () => void;
  availableActions?: string[];
};

const ItemActionBar: FC<ItemActionBarProps> = ({
  customizedComponents,
  onUpdate,
  onDelete,
  onReset,
  availableActions = ['update', 'delete', 'reset'],
}) => {
  return (
    <Box w='100%' toBetween>
      <Box black>{customizedComponents}</Box>
      <Box row spaceX2>
        {availableActions.includes('delete') && (
          <Button size='small' variant='contained' onClick={onDelete}>
            Delete
          </Button>
        )}
        {availableActions.includes('update') && (
          <Button size='small' variant='contained' onClick={onUpdate}>
            Update
          </Button>
        )}
        {availableActions.includes('reset') && (
          <Button size='small' variant='contained' onClick={onReset}>
            Reset
          </Button>
        )}
      </Box>
    </Box>
  );
};
export default ItemActionBar;
