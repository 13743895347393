import create from 'zustand';
import { DebatebaseVO, evaluateDebate, getDebatebases, updateDebatebase } from '../services/debatebase.service';

interface DebatebaseState {
  debatebases: DebatebaseVO[];
  setDebatebases: (value: DebatebaseVO[]) => void;
  getDebatebases: (
    page: number,
    limit: number,
    clearCache?: boolean
  ) => Promise<DebatebaseVO[]>;
  getDebatebasesByGenre: (
    page: number,
    limit: number,
    genre: string,
    clearCache?: boolean
  ) => Promise<DebatebaseVO[]>;
  updateDebatebase: (vo: DebatebaseVO) => Promise<boolean>;
  evaluateDebate: (data: DebatebaseVO) => Promise<string>;
}

export const useDebatebaseStore = create<DebatebaseState>((set) => ({
  debatebases: [],
  setDebatebases: (value: DebatebaseVO[]) => {
    set({
      debatebases: value,
    });
  },
  getDebatebases: async (
    page: number,
    limit: number,
    clearCache: boolean = false
  ) => {
    if (clearCache) {
      getDebatebases.clear();
    }
    return getDebatebases(page, limit);
  },
  getDebatebasesByGenre: async (
    page: number,
    limit: number,
    genre: string,
    clearCache: boolean = false
  ) => {
    if (clearCache) {
      getDebatebases.clear();
    }
    const allSubjecs = await getDebatebases(page, limit);
    return allSubjecs.filter((s) => {
      return s.genre === genre;
    });
  },
  updateDebatebase: async (vo: DebatebaseVO) => {
    const params:Map<string,any> = new Map<string, any>();
    params.set('content', vo.content);
    params.set('genre', vo.genre);
    params.set('title', vo.title);
    params.set('wrId', vo.wrId);
    const result = await updateDebatebase(vo,params);
    return result;
  },
  evaluateDebate: async (data: DebatebaseVO): Promise<string> => {
    const result = await evaluateDebate(data);
    return result
  },
}));