import { FC } from 'react'
import { phraseGenre3Range } from '../../pages/phrase/PhrasePreset'
import EditableSelection from '../EditableSelection'


export type SpecificTypeSelectionProps = {
  selectedValue: string
  onUpdate: (newValue: string) => void
}
const SpecificTypeSelection: FC<SpecificTypeSelectionProps> = ({
  selectedValue,
  onUpdate,
}) => {
  return (
    <EditableSelection
      label="具体类型"
      selectedValue={phraseGenre3Range.find((o) => o.id === selectedValue)?.name}
      options={phraseGenre3Range.map((g) => g.id)}
      optionData={phraseGenre3Range}
      onChange={(newValue) => {
        onUpdate(newValue)
      }}
      hasReset={false}
    />
  )
}
export default SpecificTypeSelection
