import { FC } from 'react'
import { phraseGenre2Range } from '../../pages/phrase/PhrasePreset'
import EditableSelection from '../EditableSelection'



export type WritingTypeSelectionProps = {
  selectedValue: string
  onUpdate: (newValue: string) => void
}
const WritingTypeSelection: FC<WritingTypeSelectionProps> = ({
  selectedValue,
  onUpdate,
}) => {
  return (
    <EditableSelection
      label="题目分类"
      selectedValue={phraseGenre2Range.find((o) => o.id === selectedValue)?.name}
      options={phraseGenre2Range.map((g) => g.id)}
      optionData={phraseGenre2Range}
      onChange={(newValue) => {
        onUpdate(newValue)
      }}
      hasReset={false}
    />
  )
}
export default WritingTypeSelection
