import { Box } from '@fower/react'
import { Button, Pagination } from '@mui/material'
import { useMount } from 'ahooks'
import { FC, useState } from 'react'
import { CETSubjectVO } from '../../../services/subjects.service'
import { useSubjectsStore } from '../../../store/subjects.store'
import CETSubjectItem from './CETSubjectItem'
import CreateSubject from './CreateSubject'
import SearchBar from './SearchBar'
const CETSubjects: FC = () => {
  const perPage = 10
  const {
    subjects,
    getSubjects,
    setSubjectsByGenre,
    getCount,
    getSubjectsCondition,
  } = useSubjectsStore()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pages, setPages] = useState<number>(0)
  const [currentCondition, setCurrentCondition] = useState<any | null>(null)
  const [genre, setGenre] = useState<string>('cet4')
  const [showCreateSubject, setShowCreateSubject] = useState<boolean>(false)
  const refreshPages = async (toGenre:string, condition: any | null) => {
    const totalCount = await getCount(toGenre, condition)
    console.log('refreshPages',totalCount);
    
    setPages(Math.ceil(totalCount / perPage))
  }
  const reloadSubjects = async (p: number) => {
    if (currentCondition === null) {
      const result = await getSubjects(genre, p - 1, perPage)
      setSubjectsByGenre(genre, result)
    } else {
      const result = await getSubjectsCondition(
        genre,
        p - 1,
        perPage,
        currentCondition,
      )
      setSubjectsByGenre(genre, result)
    }
  }
  useMount(async () => {
    await refreshPages(genre, null)
    await reloadSubjects(currentPage)
  })
  return (
    <Box w="80%" h="100vh" column pt32>
      <Box w="80%" fixed top16 toCenterY toBetween zIndex="100">
        <SearchBar
          genre={genre}
          onSearch={async (condition: string | null, filterValue: string) => {
            let toGenre = genre
            if(condition === 'genre'){
              toGenre = filterValue
              setGenre(toGenre)
            }
            let subjects
            if (condition === 'all') {
              await refreshPages(genre,null)
              setCurrentCondition(null)
              setCurrentPage(1)
              subjects = await getSubjectsCondition(toGenre, 0, perPage, null)
            } else {
              const conditionObj = {
                [condition!]: condition === 'genre' ? String(filterValue) : Number(filterValue),
              }
              setCurrentCondition(conditionObj)
              await refreshPages(toGenre,conditionObj)
              subjects = await getSubjectsCondition(
                toGenre,
                0,
                perPage,
                conditionObj,
              )
            }
            
            setSubjectsByGenre(toGenre, subjects)
          }}
        />
        <Box row spaceX2 toCenterY>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              setGenre('cet4')
              setShowCreateSubject(true)
            }}
          >
            Add CET4
          </Button>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              setGenre('cet6')
              setShowCreateSubject(true)
            }}
          >
            Add CET6
          </Button>
        </Box>
      </Box>
      <Box column>
        {subjects.map((vo) => {
          return <CETSubjectItem key={vo._id} data={vo as CETSubjectVO} />
        })}
      </Box>
      <Box
        fixed
        right0
        bottom0
        bgWhite
        rounded
        borderBlack
        borderSolid
        border-1
        p3
      >
        <Pagination
          count={pages}
          page={currentPage}
          onChange={(_event, page) => {
            setCurrentPage(page)
            getSubjects(genre, page - 1, perPage).then((result) => {
              setSubjectsByGenre(genre, result)
            })
          }}
        />
      </Box>
      <CreateSubject
        genre={genre}
        open={showCreateSubject}
        onClose={() => {
          setShowCreateSubject(false)
        }}
      />
    </Box>
  )
}
export default CETSubjects
