import create from 'zustand';
interface AppState {
  isLogin: boolean;
  login: () => void;
  logout: () => void;
}
export const useAppStore = create<AppState>((set) => ({
  isLogin: false,
  login: () => {
    set(() => ({
      isLogin: true,
    }));
  },
  logout: () => {
    set(() => ({
      isLogin: false,
    }));
  },
}));
