import { Box } from '@fower/react'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FC, useCallback, useEffect, useState } from 'react'
import {
  yearRange,
  typeRange,
  partRange,
  monthRange,
  setRange,
} from './RangePreset'
import {
  CETSubjectVO,
  SubjectGenre,
  SubjectVO,
} from '../services/subjects.service'

export type RelatedToSubjectProps = {
  genre?: string
  selectedSubjectId?: string
  onChange: (selectedItem: SubjectVO | CETSubjectVO | undefined) => void
  subjects: Array<SubjectVO | CETSubjectVO>
}
const RelatedToSubject: FC<RelatedToSubjectProps> = ({
  genre,
  subjects,
  onChange,
  selectedSubjectId,
}) => {
  const isG = genre === SubjectGenre.GRADUATE
  const [selectedSubject, setSelectedSubject] = useState<
    SubjectVO | CETSubjectVO | undefined
  >(undefined)
  const [selectedYearIndex, setSelectedYearIndex] = useState<number>(0)
  const [selectedIndex2, setSelectedIndex2] = useState<number>(0)
  const [selectedIndex3, setSelectedIndex3] = useState<number>(0)

  const handleYearChange = (event: SelectChangeEvent) => {
    const indexTo = yearRange.indexOf(event.target.value)
    setSelectedYearIndex(indexTo)
    updateSelectedSubjectByIndexes(indexTo, selectedIndex2, selectedIndex3)
  }

  const handleOption2Change = (event: SelectChangeEvent) => {
    const targetRange = isG ? typeRange : monthRange
    const indexTo = targetRange.indexOf(event.target.value)
    setSelectedIndex2(indexTo)
    updateSelectedSubjectByIndexes(selectedYearIndex, indexTo, selectedIndex3)
  }

  const handleOption3Change = (event: SelectChangeEvent) => {
    const targetRange = isG ? partRange : setRange
    const indexTo = targetRange.indexOf(event.target.value)
    setSelectedIndex3(indexTo)
    updateSelectedSubjectByIndexes(selectedYearIndex, selectedIndex2, indexTo)
  }
  const updateSelectedSubjectByIndexes = (
    y: number,
    i2: number,
    i3: number,
  ) => {
    const year = yearRange[y]
    const cd2 = isG ? typeRange[i2] : monthRange[i2]
    const cd3 = isG ? partRange[i3] : setRange[i3]
    const target = subjects.find((t) => {
      if (isG) {
        const s = t as SubjectVO
        return String(s.year) === year && s.type === cd2 && s.part === cd3
      } else {
        const s = t as CETSubjectVO
        return (
          String(s.year) === year &&
          s.month === Number(cd2) &&
          s.set === Number(cd3)
        )
      }
    })
    setSelectedSubject(target)
    onChange(target)
    return target
  }
  const updateIndexesBySubject = useCallback(
    (subject: SubjectVO | CETSubjectVO) => {
      if (!subject) return
      const year = yearRange.indexOf(String(subject.year))
      setSelectedYearIndex(year)
      if (subject.genre === SubjectGenre.GRADUATE) {
        const s = subject as SubjectVO
        const i2 = typeRange.indexOf(s.type)
        setSelectedIndex2(i2)
        const i3 = partRange.indexOf(s.part)
        setSelectedIndex3(i3)
      } else {
        const s = subject as CETSubjectVO
        const i2 = monthRange.indexOf(s.month.toString())
        setSelectedIndex2(i2)
        const i3 = setRange.indexOf(s.set.toString())
        setSelectedIndex3(i3)
      }
      setSelectedSubject(subject)
    },
    [],
  )

  useEffect(() => {
    if (selectedSubjectId) {
      for (let index = 0; index < subjects.length; index++) {
        const element = subjects[index]
        if (element._id === selectedSubjectId) {
          updateIndexesBySubject(element)
        }
      }
    } else {
      updateIndexesBySubject(subjects[0])
    }
  }, [selectedSubjectId, subjects, updateIndexesBySubject])
  return (
    <Box w="100%" row toBetween>
      <Box row toCenterY black>
        {selectedSubject?.subtitle}
      </Box>
      <Box row spaceX2 toCenterY>
        <Select
          value={yearRange[selectedYearIndex]}
          label="Year"
          onChange={handleYearChange}
          size="small"
        >
          {yearRange.map((year, index) => {
            return (
              <MenuItem value={year} key={`${year}-${index}`}>
                {year}年
              </MenuItem>
            )
          })}
        </Select>
        {isG ? (
          <>
            <Select
              value={typeRange[selectedIndex2] || typeRange[0]}
              label="类型"
              onChange={handleOption2Change}
              size="small"
            >
              {typeRange.map((type, index) => {
                return (
                  <MenuItem value={type} key={`${type}-${index}`}>
                    类型:{type}
                  </MenuItem>
                )
              })}
            </Select>
            <Select
              value={partRange[selectedIndex3] || partRange[0]}
              label="部分"
              onChange={handleOption3Change}
              size="small"
            >
              {partRange.map((part, index) => {
                return (
                  <MenuItem value={part} key={`${part}-${index}`}>
                    {part === 'A' ? '小作文' : '大作文' }
                  </MenuItem>
                )
              })}
            </Select>
          </>
        ) : (
          <>
            <Select
              value={monthRange[selectedIndex2] || monthRange[0]}
              label="月份"
              onChange={handleOption2Change}
              size="small"
            >
              {monthRange.map((month, index) => {
                return (
                  <MenuItem value={month} key={`${month}-${index}`}>
                    {month}月
                  </MenuItem>
                )
              })}
            </Select>
            <Select
              value={setRange[selectedIndex3] || setRange[0]}
              label="套题"
              onChange={handleOption3Change}
              size="small"
            >
              {setRange.map((set, index) => {
                return (
                  <MenuItem value={set} key={`${set}-${index}`}>
                    第{set}套
                  </MenuItem>
                )
              })}
            </Select>
          </>
        )}
      </Box>
    </Box>
  )
}
export default RelatedToSubject
