import produce from 'immer'
import create from 'zustand'
import {
  addPhrase,
  deletePhrase,
  getCount,
  getPhrasesWithContion,
  PhraseVO,
  updatePhrase,
} from '../services/phrase.service'
export interface PhraseState {
  phrases: Array<PhraseVO>
  getCount: (condition:any|null)=>Promise<number>
  refreshPhrases: (
    condition: any | null,
    page: number,
    limit: number,
  ) => Promise<boolean>
  updatePhrase: (updatedVO: PhraseVO) => Promise<boolean>
  addPhrase: (newPhrase: PhraseVO) => Promise<boolean>
  deletePhrase: (id: string) => Promise<boolean>
}

export const usePhraseStore = create<PhraseState>((set) => ({
  phrases: [],
  async getCount(condition:any|null) {
    const result = await getCount(condition)
    return result
  },
  async refreshPhrases(condition, page, limit): Promise<boolean> {
    try {
      const newList: Array<PhraseVO> = await getPhrasesWithContion(
        condition,
        page,
        limit,
      )
      set(() => ({
        phrases: newList,
      }))
      return true
    } catch (error) {
      return false
    }
  },
  async updatePhrase(updatedVO: PhraseVO): Promise<boolean> {
    const id = updatedVO._id
    delete updatedVO._id
    delete updatedVO._openid
    console.log(updatedVO)

    const result = await updatePhrase(id!, updatedVO)
    if (result) {
      set(
        produce((state: PhraseState) => {
          const newPhrases = state.phrases.map((p: any) => {
            if (p._id === id) {
              return { _id: id, ...updatedVO }
            }
            return p
          })
          state.phrases = newPhrases
        }),
      )
    }
    return result
  },
  async addPhrase(newPhrase): Promise<boolean> {
    const result = await addPhrase(newPhrase)
    if (result.success) {
      set(
        produce((state: PhraseState) => {
          newPhrase = {...newPhrase, _id: result.id}
          state.phrases.unshift(newPhrase)
        }),
      )
    }
    return result.success
  },
  async deletePhrase(id: string): Promise<boolean> {
    const result = await deletePhrase(id)
    if (result) {
      set(
        produce((state: PhraseState) => {
          state.phrases = state.phrases.filter((p) => {
            return p._id !== id
          })
        }),
      )
    }
    return result
  },
}))
