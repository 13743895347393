import { OptionVO } from '../pages/phrase/PhrasePreset'

export const yearRange: string[] = [
  '2010',
  '2011',
  '2012',
  '2013',
  '2014',
  '2015',
  '2016',
  '2017',
  '2018',
  '2019',
  '2020',
  '2021',
  '2022',
].reverse()
export const monthRange: string[] = [
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
].reverse()
export const typeRange: string[] = ['1', '2']
export const partRange: string[] = ['A', 'B']
export const partRangeData: OptionVO[] = [
  {id: 'A', name: '小作文'},
  {id: 'B', name: '大作文'},
]
export const setRange: string[] = ['1', '2', '3', '4', '5', '6']
export const debateBaseGenresRange: string[] = [
  'culture',
  'economy',
  'education',
  'environment',
  'free-speech-debate',
  'health',
  'international',
  'law',
  'philosophy',
  'politics',
  'religion',
  'science',
  'society',
  'sport',
  'digital-freedoms',
]
export const genreRange: string[] = ['graduate', 'cet4', 'cet6']
export const genreRangeData: OptionVO[] = [
  {id:'graduate', name:'研究生'},
  {id:'cet4', name:'大学英语四级'},
  {id:'cet6', name:'大学英语六级'}
]


export const writingStatusRange: string[] = ['virgin', 'exuberant', 'waifu']
