import { Box } from '@fower/react';
import { Button } from '@mui/material';
import { FC } from 'react';

export type ActionBarProps = {
  onCreate: () => void;
};

const ActionBar: FC<ActionBarProps> = ({ onCreate }) => {
  return (
    <Box black>
      <Button size='large' variant='contained' onClick={onCreate}>Add</Button>
    </Box>
  );
};
export default ActionBar;
