import { Box } from '@fower/react'
import { Button } from '@mui/material'
import { FC } from 'react'
import { partRangeData, genreRangeData } from '../../../components/RangePreset'
import { MatrixSubjectVO } from '../../../services/matrix.service'
import { phraseGenre2Range } from '../../phrase/PhrasePreset'
export type CurrentMatrixSubjectProps = {
  subject?: MatrixSubjectVO
  hasNode: boolean
  onCreateNode: () => void
  onBack: () => void
}
const CurrentMatrixSubject: FC<CurrentMatrixSubjectProps> = ({
  subject,
  hasNode,
  onCreateNode,
  onBack
}) => {
  if (!subject) {
    return <></>
  }

  return (
    <Box bgGray800 rounded p6 column spaceY6>
      <Box row toCenterY toBetween>
        <Button variant='contained' onClick={onBack}>返回</Button>
      <Box text2XL white>
        当前主题：{subject.subtitle}
      </Box>
      </Box>
      <Box row toCenterY spaceX4>
        <Box white>
          {genreRangeData.find((o) => o.id === subject.genre)?.name}
        </Box>
        <Box white>
          {partRangeData.find((o) => o.id === subject.genre2)?.name}
        </Box>
        <Box white>
          {phraseGenre2Range.find((o) => o.id === subject.type)?.name}
        </Box>
      </Box>
      {!hasNode && (
        <Box toCenter column spaceY10>
          <Box white textXL>
            当前主题还没有相关问题
          </Box>
          <Button variant="contained" color="success" onClick={onCreateNode}>
            添加第一个问题
          </Button>
        </Box>
      )}
    </Box>
  )
}
export default CurrentMatrixSubject
