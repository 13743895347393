import { Box } from '@fower/react'
import { useMount } from 'ahooks'
import { FC, useState } from 'react'
import { AnnotationVO } from '../../services/annotation.service'
import { SubjectGenre } from '../../services/subjects.service'
import { useSubjectsStore } from '../../store/subjects.store'
import { useWritingsStore } from '../../store/writings.store'
import CreateAnnotation from './CreateAnnotation'
import SideMenuBar from './SideMenuBar'
import { WritingListItem } from './WritingItem'
import WRSearchBar from './WRSearchBar'

const Writings: FC = () => {
  const { subjects, getSubjects, setSubjectsByGenre } = useSubjectsStore()
  const {
    writings,
    getWritings,
    setWritings,
    getWritingsWithCondition,
  } = useWritingsStore()
  const [editMode, setEditMode] = useState<'edit' | 'create'>('create')
  const [showCreateAnnotation, setShowCreateAnnotation] = useState<boolean>(
    false,
  )
  const [annotation, setAnnotation] = useState<AnnotationVO | null>(null)
  useMount(async () => {
    //TODO should adapte genre
    const sjs = await getSubjects(SubjectGenre.GRADUATE, 0, 60)
    setSubjectsByGenre(SubjectGenre.GRADUATE, sjs)
    const wrs = await getWritings(0, 60)
    setWritings(wrs)
  })
  return (
    <Box h="100vh" w="100%" toCenterX column pt32 spaceY2>
      <Box w="80%" fixed top16 toCenterY toLeft zIndex="100">
        <WRSearchBar
          onSearch={(params) => {
            if (!params.author && !params.status) {
              getWritings(0, 100).then((wrs) => {
                setWritings(wrs)
              })
            } else {
              getWritingsWithCondition(0, 100, params).then((wrs) => {
                setWritings(wrs)
              })
            }
          }}
        />
      </Box>
      {writings.map((w, i) => {
        const targetSubject = subjects.find((s) => s._id === w.subjectId)
        return (
          <WritingListItem
            key={i}
            data={w}
            subject={targetSubject}
            onSelectionChange={(wrID, start, end, text) => {
              setAnnotation({
                wrID,
                start,
                end,
                comment: '',
                rawContent: text,
                type: 'correction',
              })
            }}
            onEditAnnotation={(annotation) => {
              setAnnotation(annotation)
              setEditMode('edit')
              setShowCreateAnnotation(true)
            }}
          ></WritingListItem>
        )
      })}
      <CreateAnnotation
        annotation={annotation}
        mode={editMode}
        open={showCreateAnnotation}
        onClose={(newVO) => {
          setShowCreateAnnotation(false)
        }}
      />
      <SideMenuBar
        onAddAnnotation={() => {
          setEditMode('create')
          setShowCreateAnnotation(true)
        }}
      />
    </Box>
  )
}
export default Writings
