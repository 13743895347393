import { Box } from '@fower/react'
import { FC, useState } from 'react'
import EditableSelection from '../../components/EditableSelection'
import EditableTextArea from '../../components/EditableTextArea'
import ItemActionBar from '../../components/ItemActionBar'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'

import RelatedToSubject from '../../components/RelatedToSubject'
import { PhraseVO } from '../../services/phrase.service'
import { CETSubjectVO, SubjectVO } from '../../services/subjects.service'
import CopyBar from '../home/components/CopyBar'
import {
  getDescForGenre,
  phraseGenre2Range,
  phraseGenre3Range,
  sentenceRange,
  paragraphRange,
} from './PhrasePreset'
export type PhraseItemProps = {
  item: PhraseVO
  subjects: Array<SubjectVO | CETSubjectVO>
  onUpdate: (newItem: PhraseVO) => void
  onDelete: (id: string) => void
}
const PhraseItem: FC<PhraseItemProps> = ({
  item,
  subjects,
  onUpdate,
  onDelete,
}) => {
  const [showMore, setShowMore] = useState<boolean>(false)
  return (
    <Box w="80%" p5 bgWhite rounded column spaceY2>
      <Box w-100p toCenterY toBetween>
        <CopyBar text={item._id} />
        <Box w-100p row toRight>
          {showMore ? (
            <Box onClick={()=>{
              setShowMore(false)
            }}>
              <UnfoldLessIcon />
            </Box>
          ) : (
            <Box onClick={()=>{
              setShowMore(true)
            }}>
              <UnfoldMoreIcon />
            </Box>
          )}
        </Box>
      </Box>

      <RelatedToSubject
        genre={item.genre1}
        selectedSubjectId={item.subjectId}
        subjects={subjects}
        onChange={(subject) => {
          if (!subject) return
          const newItem: PhraseVO = {
            ...item,
            subjectId: subject?._id,
          }
          onUpdate(newItem)
        }}
      />

      <Box row spaceX2 toCenterY>
        <EditableSelection
          label="分类2"
          selectedValue={getDescForGenre(item.genre2, phraseGenre2Range)}
          options={phraseGenre2Range.map((o) => o.id)}
          optionData={phraseGenre2Range}
          onChange={(newValue) => {
            const newItem: PhraseVO = {
              ...item,
              genre2: newValue,
            }
            onUpdate(newItem)
          }}
          hasReset={false}
        />
        <EditableSelection
          label="分类3"
          selectedValue={getDescForGenre(item.genre3, phraseGenre3Range)}
          options={phraseGenre3Range.map((o) => o.id)}
          optionData={phraseGenre3Range}
          onChange={(newValue) => {
            const newItem: PhraseVO = {
              ...item,
              genre3: newValue,
            }
            onUpdate(newItem)
          }}
          hasReset={false}
        />
        <EditableSelection
          label="段"
          selectedValue={String(item.paragraph)}
          options={paragraphRange}
          onChange={(newValue) => {
            const newItem: PhraseVO = {
              ...item,
              paragraph: Number(newValue),
            }
            onUpdate(newItem)
          }}
          hasReset={false}
        />
        <EditableSelection
          label="句"
          selectedValue={String(item.sentence)}
          options={sentenceRange}
          onChange={(newValue) => {
            const newItem: PhraseVO = {
              ...item,
              sentence: Number(newValue),
            }
            onUpdate(newItem)
          }}
          hasReset={false}
        />
      </Box>
      <Box hidden={!showMore}>
        <EditableTextArea
          label="内容"
          text={item.content}
          onUpdate={(newValue) => {
            const newItem: PhraseVO = {
              ...item,
              content: newValue,
            }
            onUpdate(newItem)
          }}
        />
        <EditableTextArea
          label="译文"
          text={item.translation}
          onUpdate={(newValue) => {
            const newItem: PhraseVO = {
              ...item,
              translation: newValue,
            }
            onUpdate(newItem)
          }}
        />
        <EditableTextArea
          label="作用"
          text={item.functionality}
          onUpdate={(newValue) => {
            const newItem: PhraseVO = {
              ...item,
              functionality: newValue,
            }
            onUpdate(newItem)
          }}
        />
        <ItemActionBar
          onDelete={() => {
            onDelete(item._id!)
          }}
          availableActions={['delete']}
        />
      </Box>
    </Box>
  )
}
export default PhraseItem
