import { FC, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import {
  MatrixSubjectVO,
  MatrixOptionNodeVO,
  MatrixNodeVO,
} from '../../../services/matrix.service'
import { Button, Checkbox, Drawer } from '@mui/material'
import { useMatrixNodeStore } from '../../../store/matrix-node.store'
import EditableTextArea from '../../../components/EditableTextArea'

export type CreateOrEditOptionProps = {
  isOpen: boolean
  onClose: (option?: MatrixOptionNodeVO) => void
  subject?: MatrixSubjectVO
  parentNode?: MatrixNodeVO
  option?: MatrixOptionNodeVO
  mode: 'edit' | 'create'
}
const CreateOrEditOption: FC<CreateOrEditOptionProps> = ({
  subject,
  option,
  mode,
  isOpen,
  onClose,
  parentNode,
}) => {
  const { newOption, updateOption, addOptionTo } = useMatrixNodeStore()
  const targetOption = mode === 'create' ? newOption : option
  const [text, setText] = useState<string>('')
  const [phrase, setPhrase] = useState<string>('')
  const [translation, setTranslation] = useState<string>('')
  const [functionality, setFunctionality] = useState<string>('')
  const [badChoice, setBadChoice] = useState<boolean>(false)
  const [parentNodeName, setParentNodeName] = useState<string>('')

  useEffect(() => {
    if (targetOption && parentNode) {
      setParentNodeName(parentNode.text)
      setText(targetOption?.text!)
      setPhrase(targetOption?.phrase!)
      setTranslation(targetOption?.translation!)
      setFunctionality(targetOption?.functionality!)
      setBadChoice(targetOption?.badChoice!)
    }
    return () => {
      setText('')
      setPhrase('')
      setTranslation('')
      setFunctionality('')
      setBadChoice(false)
    }
  }, [parentNode, targetOption])
  if (!subject) {
    return <></>
  }
  const onCreate = () => {
    if (newOption !== null && parentNode !== undefined) {
      const _newOption: MatrixOptionNodeVO = {
        ...newOption,
        text,
        phrase,
        translation,
        functionality,
        badChoice,
      }
      addOptionTo(_newOption, parentNode).then((option) => {
        onClose(option)
      })
    }
  }
  const onUpdate = () => {
    if(!parentNode) return 
    let _newOption: MatrixOptionNodeVO = {
      ...targetOption!,
      text,
      phrase,
      translation,
      functionality,
      badChoice,
    }
    updateOption(_newOption, parentNode).then((option) => {
      onClose(option)
    })
  }

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        onClose()
      }}
    >
      <Box w="480px" h-100p bgWhite p6 column spaceY4>
        <Box text2XL fontBold>
          {mode === 'edit' ? '编辑选项' : '创建选项'}
        </Box>
        <Box>回答的问题：{parentNodeName}</Box>
        <EditableTextArea
          label="选项描述"
          text={text}
          onUpdate={(newValue: string) => {
            setText(newValue)
          }}
          immediateUpdate={true}
        />
        <EditableTextArea
          label="对应例句 En"
          text={phrase}
          onUpdate={(newValue: string) => {
            setPhrase(newValue)
          }}
          immediateUpdate={true}
        />
        <EditableTextArea
          label="例句翻译 Zh"
          text={translation}
          onUpdate={(newValue: string) => {
            setTranslation(newValue)
          }}
          immediateUpdate={true}
        />
        <EditableTextArea
          label="例句功能 (optional)"
          text={functionality}
          onUpdate={(newValue: string) => {
            setFunctionality(newValue)
          }}
          immediateUpdate={true}
        />
        <Box row toCenterY>
          <Box>一个愚蠢的答案？</Box>
          <Checkbox
            checked={badChoice}
            onChange={(e) => {
              setBadChoice(e.target.checked)
            }}
          />
        </Box>

        <Button
          variant="contained"
          onClick={() => {
            if (mode === 'create') {
              onCreate()
            } else {
              onUpdate()
            }
          }}
        >
          {mode === 'create' ? '创建' : '保存'}
        </Button>
      </Box>
    </Drawer>
  )
}
export default CreateOrEditOption
