import {FC} from 'react';
import { Box } from '@fower/react';
  
export type MatrixSearchBarProps = {
}
const MatrixSearchBar:FC<MatrixSearchBarProps> = ({}) => {
  
  return (
    <Box>
      
    </Box>
  );
};
export default MatrixSearchBar