import { Box } from '@fower/react';
import { FC, useState } from 'react';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { Alert, Snackbar } from '@mui/material';
export type CopyBarProps = {
  text?: string;
};
const CopyBar: FC<CopyBarProps> = ({ text }) => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const handleClose = () => {
    setShowMessage(false);
  };
  return (
    <Box
      row
      spaceX2
      onClick={() => {
        navigator.clipboard.writeText(text || '').then(() => {
          setShowMessage(true);
        });
      }}
    >
      <Box black>{text}</Box>
      <CopyAllIcon fontSize='small' color='primary' />
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showMessage}
        autoHideDuration={1000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity='success' sx={{ width: '100%' }}>
          Copied!
        </Alert>
      </Snackbar>
    </Box>
  );
};
export default CopyBar;
