import { Box } from '@fower/react'
import { Checkbox } from '@mui/material'
import { FC, useState } from 'react'
export type EditableCheckBoxProps = {
  checked: boolean
  label: string
  onUpdate: (value: boolean) => void
  immediateUpdate?: boolean
}
const EditableCheckBox: FC<EditableCheckBoxProps> = ({
  label,
  checked,
  onUpdate,
}) => {
  const [editingValue, setEditingValue] = useState<boolean>(checked)
  return (
    <Box row toCenterY>
      <Box fontExtrabold mr2 black>
        {label}
      </Box>
      <Checkbox
        checked={editingValue}
        onChange={(_event, value) => {
          setEditingValue(value)
          onUpdate(value)
        }}
      />
    </Box>
  )
}
export default EditableCheckBox
