import { Box } from '@fower/react';
import { Button, TextField } from '@mui/material';
import { FC, useEffect, useState } from 'react';

const EditableTextArea: FC<{
  label: string;
  text: string;
  onUpdate: (newValue: string) => void;
  immediateUpdate?:boolean;
}> = ({ label, text, onUpdate, immediateUpdate = false }) => {
  
  const [editing, setEditing] = useState<boolean>(false);
  const [editingValue, setEditingValue] = useState<string>(text);
  useEffect(()=>{
    setEditingValue(text)
  },[text])
  return (
    <Box column spaceY2 p2>
      <Box column toCenterY spaceY2>
        <Box fontExtrabold mr2 black>
          {label}
        </Box>
        <TextField
          sx={{
            width: '100%',
            zIndex: '1'
          }}
          multiline
          margin="dense"
          value={immediateUpdate ? text : editingValue}
          onBlur={() => {
            if(immediateUpdate) return
            setTimeout(() => {
              setEditing(false);
            }, 200);
          }}
          onFocus={() => {
            if(immediateUpdate) return
            setEditing(true);
          }}
          onChange={(event) => {
            setEditingValue(event.target.value);
            if(immediateUpdate){
              onUpdate(event.target.value)
            }
          }}
        />
      </Box>
      {(editing && !immediateUpdate) && (
        <Box spaceX2 mr2>
          <Button
            variant='contained'
            size='small'
            onClick={() => {
              onUpdate(editingValue);
              setEditing(false);
            }}
          >
            update
          </Button>
          <Button
            variant='contained'
            size='small'
            onClick={() => {
              setEditingValue(text);
              setEditing(false);
            }}
          >
            reset
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default EditableTextArea;
