import create from 'zustand'
import {
  createME,
  deleteME,
  evaluateEssay,
  getCount,
  getMEs,
  getMEsBySubjectID,
  ModelEssayVO,
  updateMEProps,
} from '../services/model-eassy.service'
import produce from 'immer'

interface ModelEssayState {
  modeleassys: ModelEssayVO[]
  setModelEassys: (value: ModelEssayVO[]) => void
  getModelEssays: (
    genre: string,
    page: number,
    limit: number,
    clearCache?: boolean,
  ) => Promise<ModelEssayVO[]>
  getModelEssaysBySubjectId: (
    page: number,
    limit: number,
    subjectId: string,
    clearCache?: boolean,
  ) => Promise<ModelEssayVO[]>

  createModelEssay: (data: ModelEssayVO) => Promise<ModelEssayVO | null>
  updateModelEssay: (vo: ModelEssayVO) => Promise<boolean>
  deleteModelEssay: (data: ModelEssayVO) => Promise<boolean>
  evaluateEssay: (data: ModelEssayVO) => Promise<string>
  getCount: (genre: string, subjectId: string | null) => Promise<number>
}

export const useModelEssayStore = create<ModelEssayState>((set) => ({
  modeleassys: [],
  setModelEassys: (value: ModelEssayVO[]) => {
    set({
      modeleassys: value,
    })
  },
  getModelEssays: async (
    genre: string,
    page: number,
    limit: number,
    clearCache: boolean = false,
  ) => {
    if (clearCache) {
      getMEs.clear()
    }
    return getMEs(genre, page, limit)
  },
  getModelEssaysBySubjectId: async (
    page: number,
    limit: number,
    subjectId: string,
    clearCache: boolean = false,
  ) => {
    if (clearCache) {
      getMEsBySubjectID.clear()
    }
    const allSubjecs = await getMEsBySubjectID(subjectId, page, limit)
    return allSubjecs
  },
  async createModelEssay(data) {
    await createME(data)
    return Promise.resolve(null)
  },
  updateModelEssay: async (vo: ModelEssayVO) => {
    const params: Map<string, any> = new Map<string, any>()
    params.set('text', vo.text)
    params.set('subjectId', vo.subjectId)
    params.set('wrId', vo.wrId)
    const result = await updateMEProps(vo, params)
    if (result) {
      set(
        produce((state) => {
          const newModelEssays = state.modeleassys.map((s: any) => {
            if (s._id === vo._id) {
              return { ...vo }
            }
            return s
          })
          state.modeleassys = newModelEssays
        }),
      )
    }
    return result
  },
  deleteModelEssay: async (data: ModelEssayVO): Promise<boolean> => {
    const result = await deleteME(data)
    return result
  },
  evaluateEssay: async (data: ModelEssayVO): Promise<string> => {
    const result = await evaluateEssay(data)
    return result
  },
  getCount: (genre: string, subjectId: string | null): Promise<number> => {
    return getCount(genre, subjectId)
  },
}))
