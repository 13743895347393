import create from 'zustand'
import produce from 'immer'
import {
  createSubject,
  deleteMatrixSubject,
  getCount,
  getSubjects,
  getSubjectsCondition,
  MatrixGenre,
  MatrixSubjectVO,
  newEmptyObject,
  NewMatrixSubjectVO,
  updateSubject,
} from '../services/matrix.service'
import { updateObjectPropsById } from '../services/common.service'
interface MatrixSubjectState {
  subjects: Array<MatrixSubjectVO>
  newSubject: NewMatrixSubjectVO | null
  initNewObject: (type: MatrixGenre) => void
  getCount: (genre: string, condition: any | null) => Promise<number>
  updateSubject: (
    vo: MatrixSubjectVO,
    key: string,
    value: any,
  ) => Promise<boolean>
  updateWholeSubject: (
    vo: MatrixSubjectVO,
    params: Map<string, any>,
  ) => Promise<boolean>
  updateNewSubject: (value: NewMatrixSubjectVO | null) => void
  createNewSubject: (value: NewMatrixSubjectVO) => Promise<string>

  getSubjects: (page: number, limit: number) => Promise<Array<MatrixSubjectVO>>
  getSubjectsCondition: (
    page: number,
    limit: number,
    condition: any | null,
  ) => Promise<Array<MatrixSubjectVO>>
  setSubjects: (value: Array<MatrixSubjectVO>) => void
  deleteSubject: (value: MatrixSubjectVO) => Promise<boolean>
}
export const useMatrixSubjectsStore = create<MatrixSubjectState>((set) => ({
  subjects: [],
  newSubject: null,
  initNewObject: (type: MatrixGenre) => {
    set({
      newSubject: newEmptyObject(type),
    })
  },
  getCount: async (condition: any | null) => {
    return await getCount(condition)
  },
  getSubjects: async (page, limit) => {
    const result = await getSubjects(page - 1, limit)
    return result
  },
  getSubjectsCondition: async (
    page: number,
    limit: number,
    condition: any | null,
  ) => {
    if (condition === null) return await getSubjects(page, limit)
    const result = await getSubjectsCondition(page, limit, condition)
    return result
  },
  setSubjects: (value: Array<MatrixSubjectVO>) => {
    set(() => ({
      subjects: value,
    }))
  },
  createNewSubject: async (value: NewMatrixSubjectVO): Promise<string> => {
    const newID = await createSubject(value)
    const newSubject:MatrixSubjectVO = {
      ...value,
      _id: newID
    }
    set(produce((state) => {
      state.subjects.unshift(newSubject)
    }))
    return newID
  },
  updateSubject: async (vo: MatrixSubjectVO, key: string, value: any) => {
    const result = await updateSubject(vo, key, value)
    if (result) {
      set(
        produce((state) => {
          const newSubjects = state.subjects.map((s: any) => {
            if (s._id === vo._id) {
              return { ...vo }
            }
            return s
          })
          state.subjects = newSubjects
        }),
      )
    }
    return result
  },
  updateWholeSubject: async (vo: MatrixSubjectVO, params: Map<string, any>) => {
    const collectionName = 'subject-matrix'
    const result = await updateObjectPropsById(collectionName, vo._id, params)
    if (result) {
      set(
        produce((state) => {
          const newSubjects = state.subjects.map((s: any) => {
            if (s._id === vo._id) {
              return { ...vo }
            }
            return s
          })
          state.subjects = newSubjects
        }),
      )
    }
    return result
  },
  updateNewSubject: (value: NewMatrixSubjectVO | null) => {
    set({
      newSubject: value,
    })
  },
  deleteSubject(value) {
    return deleteMatrixSubject(value)
  },
}))
