import { useMount } from 'ahooks'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './index.css'
import ModelEssays from './pages/modelessays/ModelEssays'
import Login from './pages/home/components/Login'
import Nav from './pages/home/components/Nav'
import Home from './pages/home/Home'
import { useAuth } from './services/auth'
import { useAppStore } from './store/app.store'
import DebateBase from './pages/debatebase/DebateBase'
import Writings from './pages/writings/Writings'
import HomeCET from './pages/home/HomeCET'
import Phrase from './pages/phrase/Phrase'
import { createTheme, ThemeProvider } from '@mui/material'
import Matrix from './pages/matrix/Matrix'

function App() {
  const { isLogin, login, logout } = useAppStore()
  const auth = useAuth()

  useMount(() => {
    if (auth.currentUser) {
      login()
    } else {
      logout()
    }
  })
  const theme = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            
          },
        },
      },
    },
  })
  return (
    <ThemeProvider theme={theme}>
      {isLogin ? (
        <BrowserRouter>
          {isLogin && <Nav />}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/cet" element={<HomeCET />} />
            <Route path="/modelessays" element={<ModelEssays />} />
            {/* <Route path='/' element={<DebateBase />} /> */}
            <Route path="/debatebase" element={<DebateBase />} />
            <Route path="/writings" element={<Writings />} />
            <Route path="/phrase" element={<Phrase />} />
            <Route path="/matrix" element={<Matrix />} />
          </Routes>
        </BrowserRouter>
      ) : (
        <Login />
      )}
    </ThemeProvider>
  )
}
export default App
