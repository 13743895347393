import { Box } from '@fower/react'
import { FC, useState } from 'react'
import { CETSubjectVO, SubjectGenre } from '../../../services/subjects.service'
import { useSubjectsStore } from '../../../store/subjects.store'
import EditableText from '../../../components/EditableText'
import EditableTextArea from '../../../components/EditableTextArea'
import CopyBar from './CopyBar'
import EditableStringArray from '../../../components/EditableStringArray'
import EditableSelection from '../../../components/EditableSelection'
import {
  yearRange,
  genreRange,
  monthRange,
  setRange,
} from '../../../components/RangePreset'
import { Button } from '@mui/material'
import EditableCheckBox from '../../../components/EditableCheckBox'
import { styled } from '@fower/styled'
const StyledImage = styled('img')
const CETSubjectItem: FC<{ data: CETSubjectVO }> = ({ data }) => {
  const { updateSubject, updateWholeSubject } = useSubjectsStore()
  const [year, setYear] = useState<number>(data.year)
  const [month, setMonth] = useState<number>(data.month)
  const [set, setSet] = useState<number>(data.set)
  const [genre, setGenre] = useState<string>(data.genre)
  const [archived, setArchived] = useState<boolean>(data.archived)
  if (data.genre === SubjectGenre.GRADUATE) return <></>
  return (
    <Box m2 p5 rounded bgWhite column spaceY2>
      <CopyBar text={data._id} />
      <Box row toCenterY spaceX2>
        <EditableCheckBox
          label="存档"
          checked={archived}
          onUpdate={(value) => {
            setArchived(value)
          }}
        />
        <EditableSelection
          label="genre"
          options={genreRange}
          selectedValue={data.genre}
          onChange={(newValue) => {
            setGenre(String(newValue))
          }}
        />
        <EditableSelection
          label="年"
          options={yearRange}
          selectedValue={data.year}
          onChange={(newValue) => {
            setYear(Number(newValue))
          }}
        />
        <EditableSelection
          label="月"
          options={monthRange}
          selectedValue={data.month.toString()}
          onChange={(newValue) => {
            setMonth(Number(newValue))
          }}
        />
        <EditableSelection
          label="套题"
          options={setRange}
          selectedValue={data.set}
          onChange={(newValue) => {
            setSet(Number(newValue))
          }}
        />
        <Box spaceX2>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              const params = new Map<string, any>()
              params.set('year', year)
              params.set('month', month)
              params.set('set', set)
              params.set('genre', genre)
              params.set('archived', archived)
              updateWholeSubject(data, params)
            }}
          >
            update
          </Button>
        </Box>
      </Box>
      <EditableText
        label="标题"
        text={data.subtitle}
        onUpdate={(newValue) => {
          updateSubject(data, 'subtitle', newValue).then((success) => {
            if (success) {
              data.subtitle = newValue
            }
          })
        }}
      />
      <EditableText
        label={'关键词'}
        text={data.keywords}
        onUpdate={(newValue) => {
          updateSubject(data, 'keywords', newValue).then((success) => {
            if (success) {
              data.keywords = newValue
            }
          })
        }}
      />
      <EditableText
        label={'Image'}
        text={data.image}
        onUpdate={(newValue) => {
          data.image = newValue
          updateSubject(data, 'image', newValue)
        }}
      />
      <EditableTextArea
        label={'Directions'}
        text={data.directions}
        onUpdate={(newValue) => {
          updateSubject(data, 'directions', newValue).then((success) => {
            if (success) {
              data.directions = newValue
            }
          })
        }}
      />
      <EditableStringArray
        label={'Ideas'}
        options={data.ideas}
        onUpdate={(newValue) => {
          updateSubject(data, 'ideas', newValue).then((success) => {
            if (success) {
              data.ideas = newValue
            }
          })
        }}
      />
      {(data.image !== '' && data.image !== undefined) && (
        <StyledImage
          src={data.image}
          alt="PreviewImage"
          style={{
            objectFit: 'contain',
            width: '240px',
            height: '160px',
          }}
        />
      )}
    </Box>
  )
}
export default CETSubjectItem
