import { Box } from '@fower/react'
import { FC } from 'react'
import AddCommentIcon from '@mui/icons-material/AddComment'
export type SideMenuBarProps = {
  onAddAnnotation: () => void
}
const SideMenuBar: FC<SideMenuBarProps> = ({ onAddAnnotation }) => {
  return (
    <Box
      fixed
      right0
      w="64px"
      column
      roundedBottomLeftGigantic
      roundedTopLeftGigantic
      bg="#1976d2"
      flex
      toCenter
      p5
    >
      <Box bgWhite circle10 toCenter cursorPointer onClick={onAddAnnotation}>
        <AddCommentIcon color="primary" />
      </Box>
    </Box>
  )
}
export default SideMenuBar
