import { Box } from '@fower/react'
import { Button } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { CETSubjectVO, SubjectVO } from '../../services/subjects.service'
import RelatedToSubject from '../../components/RelatedToSubject'
export type MESearchBarProps = {
  genre: string
  subjects: Array<SubjectVO | CETSubjectVO>
  onSearch: (type: 'all' | 'subject', value: string) => void
}
const MESearchBar: FC<MESearchBarProps> = ({ genre, subjects, onSearch }) => {
  const [subject, setSubject] = useState<SubjectVO | CETSubjectVO | null>(null)
  useEffect(() => {
    if (subjects.length > 0) {
      setSubject(subjects[0])
    }
  }, [subjects])
  return (
    <Box toLeft toCenterY bgWhite p2 rounded spaceX2 shadowGigantic>
      <RelatedToSubject
        genre={genre}
        subjects={subjects}
        onChange={(subjectTo) => {
          setSubject(subjectTo!)
        }}
      />
      <Button
        size="small"
        variant="contained"
        onClick={async () => {
          console.log(subject)
          onSearch('subject', subject!._id)
        }}
      >
        查询
      </Button>
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          onSearch('all', '')
        }}
      >
        全部
      </Button>
    </Box>
  )
}

export default MESearchBar
