import { Box } from '@fower/react'
import { styled } from '@fower/styled'
import {
  TextField
} from '@mui/material'
import { FC } from 'react'
import { TextDebrisVO } from '../store/writings.store'
const TextFlowElement: FC<{ data: TextDebrisVO }> = ({ data }) => {
  if (data.tagType === 'br') {
    return <br />
  }
  const StyledText = styled('span')
  const getBGColor = () => {
    if(!data.annotation) return 'transparent'
    if(data.annotation.type === 'correction') return '#FF6666'
    if(data.annotation.type === 'suggestion') return '#FCCA46'
    return '#61E294'
  }
  return (
    <StyledText
      style={{
        lineHeight: '1.4375em',
        backgroundColor: getBGColor(),
        color: 'rgba(0, 0, 0, 0)',
        fontSize: '1rem',
        fontWeight: '400',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        letterSpacing: '0.009375em',
        boxSizing: 'content-box',
        transform: 'scale(1.5)',
      }}
    >
      {data.text}
    </StyledText>
  )
}

export type EditableTextFlowProps = {
  label: string
  rawText: string
  debrisList: TextDebrisVO[]
  onSelect: (start: number, end: number, textarea: HTMLTextAreaElement) => void
}
const EditableTextFlow: FC<EditableTextFlowProps> = ({
  label,
  rawText,
  debrisList,
  onSelect,
}) => {
  return (
    <Box column spaceY2 w="100%" relative zIndex="10" overflowHidden>
      <Box fontExtrabold mr2 row toBetween toCenterY h12>
        <Box black>{label}</Box>
      </Box>
      <Box p={'14px 13px'} absolute top16 left0>
        {debrisList.map((debris, index) => {
          return <TextFlowElement key={index} data={debris} />
        })}
      </Box>
      <TextField
        sx={{
          width: '100%',
          zIndex: '1',
          fontSize: '1rem',
        }}
        multiline
        margin="dense"
        value={rawText}
        onSelectCapture={(event) => {
          const { selectionStart, selectionEnd } = event.target as any
          if (selectionStart === 0 && selectionEnd === 0) return
          const textAreaEl = event.target as HTMLTextAreaElement
          onSelect(selectionStart, selectionEnd, textAreaEl)
        }}
      />
    </Box>
  )
}
export default EditableTextFlow
