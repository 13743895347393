import { Box } from '@fower/react'
import { FC } from 'react'
import EditableSelection from './EditableSelection'
import { genreRange } from './RangePreset'

const GenereSelection: FC<{
  selectedGenre: string
  onChange: (newGenre: string) => void
}> = ({ selectedGenre, onChange }) => {
  return (
    <Box bgWhite p2 rounded>
      <EditableSelection
        label="Genre"
        options={genreRange}
        selectedValue={selectedGenre}
        onChange={(newValue) => {
          onChange(String(newValue))
        }}
      ></EditableSelection>
    </Box>
  )
}
export default GenereSelection
