import { Box } from '@fower/react'
import { Button, Input } from '@mui/material'
import { FC, useEffect, useState } from 'react'

const EditableText: FC<{
  label: string
  text: string
  onUpdate: (newValue: string) => void
  immediateUpdate?: boolean
}> = ({ label, text, onUpdate, immediateUpdate = false }) => {
  const [editing, setEditing] = useState<boolean>(false)
  const [editingValue, setEditingValue] = useState<string>(text)
  useEffect(()=>{
    setEditingValue(text)
  },[text])
  return (
    <Box toCenterY toBetween>
      <Box row w="100%" toCenterY black>
        <Box fontExtrabold mr2>
          {label}
        </Box>
        <Input
          type="text"
          value={editingValue}
          sx={{
            width: '80%',
          }}
          onBlur={() => {
            if (immediateUpdate) return
            setTimeout(() => {
              setEditing(false)
            }, 200)
          }}
          onFocus={() => {
            if (immediateUpdate) return
            setEditing(true)
          }}
          onChange={(event) => {
            if (immediateUpdate) {
              onUpdate(event.target.value)
            }
            setEditingValue(event.target.value)
          }}
        />
      </Box>
      {editing && (
        <Box spaceX2 mr2 row>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              onUpdate(editingValue)
              setEditing(false)
            }}
          >
            update
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => {
              setEditingValue(text)
              setEditing(false)
            }}
          >
            reset
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default EditableText
