import { Box } from '@fower/react'
import { Pagination } from '@mui/material'
import { useMount } from 'ahooks'
import { FC, useState } from 'react'
import ActionBar from '../../../components/ActionBar'
import { SubjectGenre, SubjectVO } from '../../../services/subjects.service'
import { useSubjectsStore } from '../../../store/subjects.store'
import CreateSubject from './CreateSubject'
import SearchBar from './SearchBar'
import SubjectItem from './SubjectItem'
const Subjects: FC = () => {
  const perPage = 10
  const {
    subjects,
    getSubjects,
    setSubjectsByGenre,
    getCount,
    getSubjectsCondition,
  } = useSubjectsStore()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pages, setPages] = useState<number>(0)
  const [currentCondition, setCurrentCondition] = useState<any | null>(null)
  const [showCreateSubject, setShowCreateSubject] = useState<boolean>(false)
  const refreshPages = async (condition: any | null) => {
    const totalCount = await getCount(SubjectGenre.GRADUATE,condition)
    setPages(Math.ceil(totalCount / perPage))
  }
  const reloadSubjects = async (p: number) => {
    if (currentCondition === null) {
      const result = await getSubjects(SubjectGenre.GRADUATE, p - 1, perPage)
      setSubjectsByGenre(SubjectGenre.GRADUATE, result)
    } else {
      const result = await getSubjectsCondition(
        SubjectGenre.GRADUATE,
        p - 1,
        perPage,
        currentCondition,
      )
      setSubjectsByGenre(SubjectGenre.GRADUATE, result)
    }
  }
  useMount(async () => {
    await refreshPages(null)
    await reloadSubjects(currentPage)
  })
  return (
    <Box w="80%" h="100%" column pt32>
      <Box w="80%" fixed top16 toCenterY toBetween zIndex="100">
        <SearchBar
          genre={SubjectGenre.GRADUATE}
          onSearch={async (condition: string | null, filterValue: string) => {
            let subjects
            if (condition === 'all') {
              await refreshPages(null)
              setCurrentCondition(null)
              setCurrentPage(1)
              subjects = await getSubjectsCondition(
                SubjectGenre.GRADUATE,
                0,
                perPage,
                null,
              )
            } else {
              if (condition === 'year') {
                const conditionObj = {
                  [condition!]: Number(filterValue),
                }
                setCurrentCondition(conditionObj)
                await refreshPages(conditionObj)
                subjects = await getSubjectsCondition(
                  SubjectGenre.GRADUATE,
                  0,
                  perPage,
                  conditionObj,
                )
              } else {
                const conditionObj = {
                  [condition!]: filterValue,
                }
                setCurrentCondition(conditionObj)
                await refreshPages(conditionObj)
                subjects = await getSubjectsCondition(
                  SubjectGenre.GRADUATE,
                  0,
                  perPage,
                  conditionObj,
                )
              }
            }
            setSubjectsByGenre(SubjectGenre.GRADUATE, subjects)
          }}
        />
        <ActionBar
          onCreate={() => {
            setShowCreateSubject(true)
          }}
        ></ActionBar>
      </Box>
      <Box column>
        {subjects.map((vo) => {
          return <SubjectItem key={vo._id} data={vo as SubjectVO} />
        })}
      </Box>
      <Box
        fixed
        right0
        bottom0
        bgWhite
        rounded
        borderBlack
        borderSolid
        border-1
        p3
      >
        <Pagination
          count={pages}
          page={currentPage}
          onChange={(_event, page) => {
            setCurrentPage(page)
            getSubjects(SubjectGenre.GRADUATE, page - 1, perPage).then(
              (result) => {
                setSubjectsByGenre(SubjectGenre.GRADUATE, result)
              },
            )
          }}
        />
      </Box>
      <CreateSubject
        genre={SubjectGenre.GRADUATE}
        open={showCreateSubject}
        onClose={() => {
          setShowCreateSubject(false)
        }}
      />
    </Box>
  )
}
export default Subjects
