import { FC, useCallback, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import {
  MatrixNodeVO,
  MatrixOptionNodeVO,
  MatrixSubjectVO,
  retrieveNodes,
  retrieveOptions,
} from '../../../services/matrix.service'
import { Breadcrumbs } from '@mui/material'
import MatrixNodeItem from './MatrixNodeItem'
import CreateOrEditNode from './CreateOrEditNode'
import { useMatrixNodeStore } from '../../../store/matrix-node.store'
import CreateOrEditOption from './CreateOrEditOption'
import CurrentMatrixSubject from './CurrentMatrixSubject'
import DeleteNodeWarning from './DeleteNodeWarning'
import PreviewWriting from './PreviewWriting'

export type MatrixNodesProps = {
  subject?: MatrixSubjectVO
  onBack: ()=>void
}
const MatrixNodes: FC<MatrixNodesProps> = ({ subject,onBack }) => {
  const { getNodes, deleteNodes } = useMatrixNodeStore()
  const { initNewNode, nodes, setNodes, initNewOption } = useMatrixNodeStore()
  const [selectedNode, setSelectedNode] = useState<MatrixNodeVO>()
  const [selectedOption, setSelectedOption] = useState<MatrixOptionNodeVO>()
  const [breadCrumbs, setBreadCrumbs] = useState<Array<MatrixNodeVO>>([])
  const [showCENodeDrawer, setShowCENodeDrawer] = useState<boolean>(false)
  const [showDeleteNodeWarning, setShowDeleteNodeWarning] = useState<boolean>(
    false,
  )
  const [showPreviewWriting, setShowPreviewWriting] = useState<boolean>(
    false,
  )
  const [optionsToPreview, setOptionsToPreview] = useState<MatrixOptionNodeVO[]>([]) 
  const [nodesToDelete, setNodesToDelete] = useState<MatrixNodeVO[]>([])
  const [ceOptionDrawerMode, setCEOptionDrawerMode] = useState<
    'create' | 'edit'
  >('create')
  const [showCEOptionDrawer, setShowCEOptionDrawer] = useState<boolean>(false)
  const onChangeSelectedNode = (id: string) => {
    const target = nodes.find((n) => n._id === id)
    if (target) {
      setSelectedNode(target)
    }
  }
  const refreshNode = useCallback(
    (setToAfter: string | undefined) => {
      if (subject) {
        getNodes(subject._id).then((nodesList) => {
          setNodes(nodesList)
          if (nodesList.length !== 0) {
            const targetNode = nodesList.find((n) => n._id === setToAfter)
            if (targetNode) {
              setSelectedNode(targetNode)
            } else {
              setSelectedNode(nodesList[0])
            }
          }
        })
      }
    },
    [getNodes, setNodes, subject],
  )
  useEffect(() => {
    if (selectedNode && nodes.length !== 0) {
      const bcs = retrieveNodes(nodes, selectedNode, [])
      setBreadCrumbs(bcs)
    }
  }, [selectedNode, nodes])
  useEffect(() => {
    refreshNode(undefined)
  }, [refreshNode])
  return (
    <Box h-100p w-100p toCenterX>
      <Box pt16 w-80p column spaceY5>
        <CurrentMatrixSubject
          onBack={onBack}
          subject={subject}
          hasNode={nodes.length !== 0}
          onCreateNode={() => {
            if (subject) {
              initNewNode(subject._id, true)
              setShowCENodeDrawer(true)
            }
          }}
        />
        <Breadcrumbs separator="›" sx={{ color: 'white' }}>
          {breadCrumbs.map((bc) => {
            return (
              <Box
                key={bc._id}
                white
                onClick={() => {
                  onChangeSelectedNode(bc._id!)
                }}
              >
                {bc.text}
              </Box>
            )
          })}
        </Breadcrumbs>

        <MatrixNodeItem
          data={selectedNode}
          onSubNodeSelected={(node) => {
            const targetNode = nodes.find((n) => n._id === node._id)
            setSelectedNode(targetNode)
          }}
          onCreateNewNode={(node, option) => {
            initNewNode(subject?._id!, false, node._id, option._id)
            setShowCENodeDrawer(true)
          }}
          onCreateNewOption={(node) => {
            initNewOption(subject!._id, node._id!)
            setCEOptionDrawerMode('create')
            setShowCEOptionDrawer(true)
          }}
          onEditOption={(option) => {
            setSelectedOption(option)
            setCEOptionDrawerMode('edit')
            setShowCEOptionDrawer(true)
          }}
          onUpdate={(newNode) => {
            console.log('newNode', newNode)

            setSelectedNode(newNode)
          }}
          onPreDelete={(_nodesToDelete) => {
            setNodesToDelete(_nodesToDelete)
            setShowDeleteNodeWarning(true)
          }}
          onPreview={(option:MatrixOptionNodeVO)=>{
            const options = retrieveOptions(option, nodes, [])
            setOptionsToPreview(options.reverse())
            setShowPreviewWriting(true)
          }}
        />
        <CreateOrEditNode
          isOpen={showCENodeDrawer}
          onClose={(node) => {
            setShowCENodeDrawer(false)
            if (node?.isRoot) {
              setSelectedNode(node)
            }
          }}
          onCloned={()=>{
            setShowCENodeDrawer(false)
            refreshNode(selectedNode?._id!)
          }}
          subject={subject}
        />
        <CreateOrEditOption
          isOpen={showCEOptionDrawer}
          onClose={(option) => {
            setShowCEOptionDrawer(false)
          }}
          mode={ceOptionDrawerMode}
          subject={subject}
          option={selectedOption}
          parentNode={selectedNode}
        />
        <PreviewWriting
          isOpen={showPreviewWriting}
          options={optionsToPreview}
          onClose={() => {
            setShowPreviewWriting(false)
          }}
        />
        <DeleteNodeWarning
          nodesToDelete={nodesToDelete}
          isOpen={showDeleteNodeWarning}
          onClose={() => {
            setShowDeleteNodeWarning(false)
          }}
          onConfirm={() => {
            const whereToGo = nodesToDelete[0].parentNode;
            deleteNodes(nodesToDelete.map(n => String(n._id!))).then(res => {
              refreshNode(whereToGo)
              setShowDeleteNodeWarning(false)
            })
          }}
        />
      </Box>
    </Box>
  )
}
export default MatrixNodes
