import { FC } from 'react'
import { Box } from '@fower/react'
import {
  MatrixNodeVO,
  MatrixOptionNodeVO,
} from '../../../services/matrix.service'
import { Button, Divider } from '@mui/material'
import { useMatrixNodeStore } from '../../../store/matrix-node.store'
import EditableTextArea from '../../../components/EditableTextArea'
import CopyBar from '../../home/components/CopyBar'

export type MatrixNodeItemProps = {
  data?: MatrixNodeVO
  onSubNodeSelected: (node: MatrixNodeVO) => void
  onCreateNewNode: (node: MatrixNodeVO, toOption: MatrixOptionNodeVO) => void
  onCreateNewOption: (node: MatrixNodeVO) => void
  onEditOption: (option: MatrixOptionNodeVO) => void
  onUpdate: (newNode: MatrixNodeVO) => void
  onPreDelete: (nodesToDelete: MatrixNodeVO[]) => void
  onPreview: (option: MatrixOptionNodeVO) => void
}
const MatrixNodeItem: FC<MatrixNodeItemProps> = ({
  data,
  onSubNodeSelected,
  onCreateNewNode,
  onCreateNewOption,
  onEditOption,
  onUpdate,
  onPreDelete,
  onPreview,
}) => {
  const {
    nodes,
    deleteOptionFrom,
    scanSubNode,
    updateNode,
    setCacheNode,
  } = useMatrixNodeStore()
  if (!data) {
    return <></>
  }
  const subNodes = nodes.filter((n) => n.parentNode === data._id)
  const parentNode = nodes.find((n) => n._id === data.parentNode)
  let fromOption = null
  if (parentNode) {
    fromOption = parentNode.options.find((o) => o._id === data.fromOption)
  }
  const onDeleteOption = (option: MatrixOptionNodeVO) => {
    deleteOptionFrom(option, data).then((newNode) => {
      onUpdate(newNode)
    })
  }

  const optionList = () => {
    return (
      <>
        <Box fontBold row toCenterY w-100p toBetween>
          <Box>选项：</Box>
          <Button
            variant="outlined"
            onClick={() => {
              onCreateNewOption(data)
            }}
          >
            添加新选项
          </Button>
        </Box>
        <Box column toCenterX spaceY2>
          {data.options?.map((n) => {
            const textToShow = n.badChoice ? `${n.text} 💩` : n.text
            return (
              <Box
                key={n._id}
                w-100p
                row
                toCenterY
                toBetween
                p3
                border="solid 1px rgba(0,0,0,0.5)"
                rounded
              >
                <Box column>
                  <Box fontBold>{textToShow}</Box>
                </Box>
                <Box maxW-40p overflowEllipsis>
                  {n.phrase}
                </Box>
                <Box row spaceX2>
                  <Button
                    variant="contained"
                    color="warning"
                    onClick={() => {
                      onPreview(n)
                    }}
                  >
                    预览
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                      onDeleteOption(n)
                    }}
                  >
                    删除
                  </Button>
                  <Button
                    variant="contained"
                    color="info"
                    onClick={() => {
                      onEditOption(n)
                    }}
                  >
                    编辑
                  </Button>
                  {!n.badChoice && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => {
                        onCreateNewNode(data, n)
                      }}
                    >
                      创建基于这个选项的后续问题
                    </Button>
                  )}
                </Box>
              </Box>
            )
          })}
        </Box>
      </>
    )
  }
  const questionList = () => {
    return (
      <>
        <Box fontBold row toCenterY w-100p toBetween>
          <Box>后续问题：</Box>
        </Box>
        <Box row toCenterY spaceX2>
          {subNodes?.map((n) => {
            const targetOption = data.options.find(
              (o) => o._id === n.fromOption,
            )
            const fromOptionText = targetOption ? targetOption.text : ''
            return (
              <Button
                key={n._id}
                variant="contained"
                onClick={() => {
                  onSubNodeSelected(n)
                }}
              >
                {`${fromOptionText} <- ${n.text}`}
              </Button>
            )
          })}
        </Box>
      </>
    )
  }
  return (
    <Box w-100p p6 bgWhite rounded spaceY2>
      {fromOption && (
        <Box row toCenterY>
          <Box >选定：</Box>
          <Box white bgGreen600 rounded p2>{fromOption.text}</Box>
        </Box>
      )}
      <CopyBar text={data._id}/>
      <EditableTextArea
        label="当前问题"
        text={data.text}
        onUpdate={(newValue) => {
          updateNode(data, 'text', newValue).then((res) => {
            if (res) {
              data.text = newValue
              setCacheNode(data)
            }
          })
        }}
      />
      {optionList()}
      <Divider />
      {questionList()}
      <Divider />
      <Box toRight>
        <Button
          variant="contained"
          color="warning"
          onClick={() => {
            const nodesToDelete = scanSubNode(data)
            onPreDelete(nodesToDelete)
          }}
        >
          删除节点
        </Button>
      </Box>
    </Box>
  )
}
export default MatrixNodeItem
