import { FC, useState } from 'react'
import { Box } from '@fower/react'
import { MatrixGenre, MatrixSubjectVO } from '../../../services/matrix.service'
import EditableText from '../../../components/EditableText'
import { useMatrixSubjectsStore } from '../../../store/matrix.store'
import TestTypeSelection from '../../../components/specific-selections/TestTypeSelection'
import PartSelection from '../../../components/specific-selections/PartSelection'
import WritingTypeSelection from '../../../components/specific-selections/WritingTypeSelection'
import EditableTextArea from '../../../components/EditableTextArea'
import { Button } from '@mui/material'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'
import SpecificTypeSelection from '../../../components/specific-selections/SpecificTypeSelection'
import EditableCheckBox from '../../../components/EditableCheckBox'

export type MatrixSubjectItemProps = {
  data: MatrixSubjectVO
  onDelete: (item: MatrixSubjectVO) => void
  onAddNode: (item: MatrixSubjectVO) => void
}
const MatrixSubjectItem: FC<MatrixSubjectItemProps> = ({
  data,
  onDelete,
  onAddNode,
}) => {
  const { updateSubject } = useMatrixSubjectsStore()
  const [showMore, setShowMore] = useState<boolean>(false)
  return (
    <Box bgWhite p6 rounded column spaceY3>
      <EditableText
        label="题目"
        text={data.subtitle}
        onUpdate={(newValue) => {
          updateSubject(data, 'subtitle', newValue).then((res) => {
            if (res) {
              data.subtitle = newValue
            }
          })
        }}
      />
      <EditableCheckBox label="已发布" checked={!data.archived} onUpdate={(newValue)=>{
        updateSubject(data, 'archived', !newValue).then((res) => {
          if (res) {
            data.archived = !newValue
          }
        })
      }}/>
      <Box row toCenterY mt3 toBetween>
        <Box row toCenterY spaceX3>
          <TestTypeSelection
            selectedValue={data.genre}
            onUpdate={(newValue) => {
              updateSubject(data, 'genre', newValue).then((res) => {
                if (res) {
                  data.genre = newValue
                }
              })
            }}
          />
          <PartSelection
            selectedValue={data.genre2}
            onUpdate={(newValue) => {
              updateSubject(data, 'genre2', newValue).then((res) => {
                if (res) {
                  data.genre2 = newValue
                }
              })
            }}
          />
          <WritingTypeSelection
            selectedValue={data.type}
            onUpdate={(newValue) => {
              updateSubject(data, 'type', newValue).then((res) => {
                if (res) {
                  data.type = newValue as MatrixGenre
                }
              })
            }}
          />
          <SpecificTypeSelection
            selectedValue={data.specificType}
            onUpdate={(newValue) => {
              updateSubject(data, 'specificType', newValue).then((res) => {
                if (res) {
                  data.specificType = newValue
                }
              })
            }}
          />
        </Box>
        <Box row toRight>
          {showMore ? (
            <Box
              onClick={() => {
                setShowMore(false)
              }}
              row
              toCenterY
            >
              <Box>收起</Box>
              <UnfoldLessIcon />
            </Box>
          ) : (
            <Box
              onClick={() => {
                setShowMore(true)
              }}
              row
              toCenterY
            >
              <Box>展开</Box>
              <UnfoldMoreIcon />
            </Box>
          )}
        </Box>
      </Box>

      {showMore && (
        <>
          <EditableText
            label="图片"
            text={data.image}
            onUpdate={(newValue) => {
              updateSubject(data, 'image', newValue).then((res) => {
                if (res) {
                  data.image = newValue
                }
              })
            }}
          />
          <EditableTextArea
            label="题面说明"
            text={data.directions}
            onUpdate={(newValue) => {
              updateSubject(data, 'directions', newValue).then((res) => {
                if (res) {
                  data.directions = newValue
                }
              })
            }}
          />
          <Box w-100p toRight row spaceX3>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                onDelete(data)
              }}
            >
              删除
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onAddNode(data)
              }}
            >
              添加/编辑 问题
            </Button>
          </Box>
        </>
      )}
    </Box>
  )
}
export default MatrixSubjectItem
