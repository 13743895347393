import { Box } from '@fower/react'
import { Pagination } from '@mui/material'
import { useMount } from 'ahooks'
import { FC, useState } from 'react'
import ActionBar from '../../components/ActionBar'
import GenereSelection from '../../components/GenreSelection'
import { PhraseVO } from '../../services/phrase.service'
import { SubjectGenre } from '../../services/subjects.service'
import { usePhraseStore } from '../../store/phrase.store'
import { useSubjectsStore } from '../../store/subjects.store'
import MESearchBar from '../modelessays/MESearchBar'
import CreatePhraseModal from './CreatePhraseModal'
import PhraseItem from './PhraseItem'
const perPage = 10
const Phrase: FC = () => {
  const { subjects, setSubjectsByGenre, getSubjects } = useSubjectsStore()
  const {
    refreshPhrases,
    updatePhrase,
    deletePhrase,
    getCount,
    phrases,
  } = usePhraseStore()
  const [genre, setGenre] = useState<string>(SubjectGenre.GRADUATE)
  const [pages, setPages] = useState<number>(0)
  const [condition, setCondition] = useState<any | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const reloadSubjects = async (iGenre: string, forceUpdate: boolean) => {
    const sjs = await getSubjects(iGenre, 0, 60)
    setSubjectsByGenre(iGenre, sjs)
  }
  const reloadPhrases = async (condition: any | null, page: number) => {
    const totalCount = await getCount(condition)
    console.log('totalCount', totalCount);
    
    setPages(Math.ceil(totalCount / perPage))
    await refreshPhrases(condition, page - 1, perPage)
  }
  useMount(async () => {
    await reloadSubjects(genre, false)
    await reloadPhrases(condition, currentPage)
  })
  return (
    <Box h="100vh" w="100%" toCenterX column pt32 spaceY2>
      <Box w="80%" fixed top16 toCenterY toBetween zIndex="100">
        <MESearchBar
          subjects={subjects}
          genre={genre}
          onSearch={(type, value) => {
            let newCondition:any = {
              subjectId: value,
            }
            if(type === 'all'){
              newCondition = null
            }
            setCondition(newCondition)
            setCurrentPage(1)
            reloadPhrases(newCondition, 1).then(null)
          }}
        />
        <GenereSelection
          selectedGenre={genre}
          onChange={async (newGenre) => {
            setGenre(newGenre)
            setCurrentPage(1)
            await reloadSubjects(newGenre, false)
          }}
        />
        <ActionBar
          onCreate={() => {
            setShowCreateModal(true)
          }}
        ></ActionBar>
      </Box>
      {phrases.map((p) => {
        return (
          <PhraseItem
            item={p}
            key={p._id}
            subjects={subjects}
            onUpdate={(newItem: PhraseVO) => {
              updatePhrase(newItem).then((res) => {
                console.log('onUpdate PhraseVO:', res)
              })
            }}
            onDelete={(id: string) => {
              deletePhrase(id).then((res) => {
                console.log('PhraseVO deleted:', res)
              })
            }}
          />
        )
      })}
      <Box
        fixed
        right0
        bottom0
        bgWhite
        rounded
        borderBlack
        borderSolid
        border-1
        p3
        zIndex="10"
      >
        <Pagination
          count={pages}
          page={currentPage}
          onChange={(_event, page) => {
            setCurrentPage(page)
            reloadPhrases(condition, page).then(null)
          }}
        />
      </Box>
      <CreatePhraseModal
        open={showCreateModal}
        subjects={subjects}
        onClose={() => {
          setShowCreateModal(false)
        }}
        genre={genre}
      />
    </Box>
  )
}
export default Phrase
