import { Box } from '@fower/react'
import { FC, useState } from 'react'
import EditableTextArea from '../../components/EditableTextArea'
import ItemActionBar from '../../components/ItemActionBar'
import { ModelEssayVO } from '../../services/model-eassy.service'
import { CETSubjectVO, SubjectVO } from '../../services/subjects.service'
import { useModelEssayStore } from '../../store/model-essay.store'
import CopyBar from '../home/components/CopyBar'
import EVAActionBar from './EVAActionBar'
import RelatedToSubject from '../../components/RelatedToSubject'

export type ModelEssayItemProps = {
  genre: string
  data: ModelEssayVO
  subjects: Array<SubjectVO | CETSubjectVO>
  onUpdate: (action: 'deleted' | 'updated') => void
}
const ModelEssayItem: FC<ModelEssayItemProps> = ({
  genre,
  data,
  subjects,
  onUpdate,
}) => {
  const {
    updateModelEssay,
    deleteModelEssay,
    evaluateEssay,
  } = useModelEssayStore()
  const [editingVO, setEditingVO] = useState<ModelEssayVO>({
    ...data,
  })
  return (
    <Box w="80%" p5 bgWhite rounded column spaceY2>
      <CopyBar text={editingVO._id} />
      <RelatedToSubject
        genre={genre}
        subjects={subjects}
        onChange={(subjectTo) => {
          setEditingVO({
            ...editingVO,
            subjectId: subjectTo?._id,
          })
        }}
        selectedSubjectId={editingVO.subjectId}
      />
      <EditableTextArea
        label={'text'}
        text={editingVO.text}
        onUpdate={(newValue) => {
          setEditingVO({
            ...editingVO,
            text: newValue,
          })
        }}
        immediateUpdate={true}
      ></EditableTextArea>
      <ItemActionBar
        customizedComponents={
          <EVAActionBar
            wrId={editingVO.wrId}
            onAction={() => {
              evaluateEssay(editingVO).then((wrId) => {
                editingVO.wrId = wrId
                updateModelEssay(editingVO).then((success) => {
                  if (success) {
                    onUpdate('updated')
                  }
                })
              })
            }}
          />
        }
        onUpdate={() => {
          updateModelEssay(editingVO).then((success) => {
            onUpdate('updated')
          })
        }}
        onDelete={() => {
          deleteModelEssay(data).then((success) => {
            onUpdate('deleted')
          })
        }}
        onReset={() => {
          setEditingVO({
            ...editingVO,
            text: data.text,
            subjectId: data.subjectId,
          })
          console.log(data.subjectId)
        }}
      />
    </Box>
  )
}
export default ModelEssayItem
