import { Box } from '@fower/react';
import { FC } from 'react';
import CETSubjects from './components/CETSubjects';

const HomeCET: FC = () => {
  return (
    <Box w='100%' h='100vh' toCenter>
      <CETSubjects />
    </Box>
  );
};
export default HomeCET;
