import { FC, useEffect, useState } from 'react'
import { Box } from '@fower/react'
import {
  cloneSubTree,
  findNodeContext,
  MatrixNodeVO,
  MatrixSubjectVO,
} from '../../../services/matrix.service'
import { Backdrop, Button, CircularProgress, Drawer } from '@mui/material'
import EditableText from '../../../components/EditableText'
import { useMatrixNodeStore } from '../../../store/matrix-node.store'

export type CreateOrEditNodeProps = {
  isOpen: boolean
  onClose: (newNode?: MatrixNodeVO) => void
  onCloned: () => void
  subject?: MatrixSubjectVO
}
const CreateOrEditNode: FC<CreateOrEditNodeProps> = ({
  subject,
  isOpen,
  onClose,
  onCloned,
}) => {
  const {
    nodes,
    newNode,
    createNewNode,
    getNodeByID,
    findExistingNodeByName,
  } = useMatrixNodeStore()
  const targetNode = newNode

  const [text, setText] = useState<string>('')
  const [parentNodeName, setParentNodeName] = useState<string>('')
  const [anwser, setAnwser] = useState<string>('')
  const [existingNodes, setExistingNode] = useState<
    Array<Array<MatrixNodeVO | undefined>>
  >([])
  const [showLoading, setShowLoading] = useState<boolean>(false)

  useEffect(() => {
    const runner = async () => {
      const parentNode = await getNodeByID(targetNode?.parentNode!)

      if (parentNode) {
        setParentNodeName(parentNode.text)
        const option = parentNode.options.find(
          (o) => o._id === targetNode?.fromOption,
        )
        if (option) {
          setAnwser(option.text)
        }
        if (targetNode && targetNode.text) {
          setText(targetNode.text)
        }
      }
    }
    runner()
    return () => {
      setText('')
      setExistingNode([])
    }
  }, [getNodeByID, targetNode])
  if (!subject) {
    return <></>
  }
  const onCreate = () => {
    if (newNode !== null) {
      const _newNode: MatrixNodeVO = {
        ...newNode,
        text,
      }
      createNewNode(_newNode).then((res) => {
        onClose(res)
      })
    }
  }
  const onSearch = () => {
    findExistingNodeByName(subject._id!, text).then((res) => {
      const ens: Array<Array<MatrixNodeVO | undefined>> = res.map((n) => {
        const context = findNodeContext(n, nodes)
        return context
      })
      setExistingNode(ens)
    })
  }
  const onClone = (fromNode: MatrixNodeVO) => {
    if (targetNode) {
      setShowLoading(true)
      cloneSubTree(
        targetNode.fromOption!,
        targetNode.parentNode!,
        fromNode,
        nodes,
      ).then(() => {
        setShowLoading(false)
        onCloned()
      })
    }
  }
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        onClose()
      }}
    >
      <Box w="600px" h-100p bgWhite p6 column spaceY4>
        <Box text2XL fontBold>
          创建问题
        </Box>
        <Box>上一个问题：{parentNodeName}</Box>
        <Box>对上一个问题的答案：{anwser}</Box>
        <EditableText
          label="新问题"
          text={text}
          onUpdate={(newValue: string) => {
            setText(newValue)
          }}
          immediateUpdate={true}
        />

        <Button
          variant="contained"
          onClick={() => {
            onSearch()
          }}
        >
          查询已有问题
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            onCreate()
          }}
        >
          创建新问题
        </Button>
        {existingNodes.map((ns) => {
          const before = ns[0]
          const current = ns[1]
          const after = ns[2]
          return (
            <Box
              key={current?._id!}
              row
              w-100p
              shadowHuge
              bgGray200
              p3
              rounded
              toBottom
              toBetween
            >
              <Box column>
                <Box row toCenterY spaceX2>
                  <Box column border='1px solid black' rounded p2> 
                    <Box textXS>上一个问题</Box>
                    <Box textSM color={before ? 'black' : 'red'}>
                      {before ? before.text : '无'}
                    </Box>
                    <Box row spaceX2 toCenterX>
                      {before?.options.filter(o=> o._id === current?.fromOption).map(o => {
                        return <Box rounded bgGreen400 white p2>{o.text}</Box>
                      })}
                    </Box>
                  </Box>
                  <Box textXL>{current?.text}</Box>
                  <Box column border='1px solid black' rounded p2>
                    <Box textXS>之后的问题</Box>
                    <Box textSM color={after ? 'black' : 'red'}>
                      {after ? after.text : '无'}
                    </Box>
                    <Box row spaceX2 toCenterX>
                      {after?.options.map(o => {
                        return <Box rounded bgGray300 p2>{o.text}</Box>
                      })}
                    </Box>
                  </Box>
                </Box>
                
              </Box>
              <Button
                variant="contained"
                onClick={() => {
                  onClone(current!)
                }}
              >
                复刻
              </Button>
            </Box>
          )
        })}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={showLoading}
        >
          <Box column spaceY2 toCenter>
            <CircularProgress color="inherit" />
            <Box>复刻节点中</Box>
          </Box>
        </Backdrop>
      </Box>
    </Drawer>
  )
}
export default CreateOrEditNode
