import { FC } from 'react'
import { Box } from '@fower/react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { MatrixNodeVO } from '../../../services/matrix.service'

export type DeleteNodeWarningProps = {
  isOpen: boolean
  onClose: () => void
  nodesToDelete: MatrixNodeVO[]
  onConfirm: () => void
}
const DeleteNodeWarning: FC<DeleteNodeWarningProps> = ({
  isOpen,
  onClose,
  nodesToDelete,
  onConfirm,
}) => {
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <DialogTitle>将要删除对应问题和下列的后续问题，可以么？</DialogTitle>
        <Box w-100p column p3 spaceY2>
          {nodesToDelete.map((n) => {
            return (
              <Box key={n._id} border="solid 1px rgba(0,0,0,0.5)" rounded row toCenterY p3>
                <Box>{n.text}</Box>
                <Box>{n.cloneFrom}</Box>
              </Box>
            )
          })}
        </Box>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            我不的
          </Button>
          <Button variant="contained" color="warning" onClick={onConfirm}>
            行吧
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
export default DeleteNodeWarning
