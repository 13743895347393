import { useState } from 'react'
import styles from './styles.module.css'
import Button from '@mui/material/Button'
import { useAuth } from '../../../services/auth'
import { Box } from '@fower/react'
import { Alert, Snackbar, TextField, Typography } from '@mui/material'
import { styled } from '@fower/styled'

const StyledImage = styled('img')
const StyledA = styled('a')

function Login() {
  const auth = useAuth()
  const [userName, setUserName] = useState<string>('')
  const [pwd, setPWD] = useState<string>('')
  const [showMessage, setShowMessage] = useState<boolean>(false)

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return
    }
    setShowMessage(false)
  }
  const onSignIn = async () => {
    try {
      // await auth.signUpWithEmailAndPassword(userName, pwd);
      await auth.signInWithEmailAndPassword(userName, pwd)
      // auth.sendPasswordResetEmail(userName)
      global.location.reload()
    } catch (error) {
      setShowMessage(true)
    }
  }

  return (
    <Box toCenter h="100vh" column>
      <Box
        className={styles.loginBox}
        toCenterX
        toTop
        bgWhite
        rounded
        shadowHuge
        column
        spaceY2
        p5
        relative
      >
        <StyledImage
          absolute
          w20
          h20
          src="/vici-outline.svg"
          roundedGigantic
          shadowHuge
          top="-4rem"
        />
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={showMessage}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
            登录名或密码错误
          </Alert>
        </Snackbar>
        <Typography
          sx={{
            color: 'gray',
            fontSize: '2rem',
          }}
        >
          文心写作CMS
        </Typography>
        <TextField
          label="User Name"
          fullWidth
          value={userName}
          onChange={(event) => {
            setUserName(event.target.value)
          }}
        />
        <TextField
          label="Password"
          fullWidth
          value={pwd}
          type="password"
          onChange={(event) => {
            setPWD(event.target.value)
          }}
        />
        <Button
          fullWidth
          variant="contained"
          onClick={() => {
            onSignIn()
          }}
        >
          Login
        </Button>
      </Box>
      <Box fixed bottom5 toCenter row spaceX2>
        <StyledImage w5 h5 src="/backup.webp" />
        <StyledA white href="https://beian.miit.gov.cn/">
          辽ICP备2022008680号-1
        </StyledA>
      </Box>
    </Box>
  )
}

export default Login
