import { Box } from '@fower/react'
import {
  Button,
  Dialog,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import DangerousIcon from '@mui/icons-material/Dangerous'
import WarningIcon from '@mui/icons-material/Warning'
import GradeIcon from '@mui/icons-material/Grade'
import { FC, useEffect, useState } from 'react'
import EditableTextArea from '../../components/EditableTextArea'
import { AnnotationVO } from '../../services/annotation.service'
import { useWritingsStore } from '../../store/writings.store'
import CopyBar from '../home/components/CopyBar'

export type CreateAnnotationProps = {
  open: boolean
  annotation: AnnotationVO | null
  onClose: (newAnnotation: AnnotationVO | null) => void
  mode: 'create' | 'edit'
}

const CreateAnnotation: FC<CreateAnnotationProps> = ({
  annotation,
  onClose,
  open,
  mode,
}) => {
  const {
    createAnnotation,
    updateAnnotationProps,
    deleteAnnotationByID,
  } = useWritingsStore()
  const [comment, setComment] = useState<string>('')
  const [correctedContent, setCorrectedContent] = useState<string>('')
  const [type, setType] = useState<
    'correction' | 'suggestion' | 'praise' | undefined
  >('correction')

  const getCommentLabel = () => {
    switch (type) {
      case 'correction':
        return '说明'
      case 'suggestion':
        return '说明'
      default:
        return '赞扬的话'
    }
  }
  useEffect(()=>{
    if(annotation){
      
      setComment(annotation.comment)
      setCorrectedContent(annotation.correctedContent!)
      setType(annotation.type)
    }
  }, [open, annotation])
  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={open}
      onClose={() => {
        onClose(null)
      }}
    >
      <DialogContent>
        <Box column w="500px" toLeft>
          <CopyBar text={annotation?.wrID} />
          <Box black>{`from: ${annotation?.start} to: ${annotation?.end}`}</Box>
          <Box p2 column spaceY2>
            <Box fontExtrabold mr2>
              原文
            </Box>
            <Box black>{annotation?.rawContent}</Box>
          </Box>
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={(event, value) => {
              setType(value)
            }}
            aria-label="text alignment"
          >
            <ToggleButton value="correction" aria-label="left aligned">
              <Box row toCenterY spaceX2>
                <DangerousIcon fontSize="small" color="error" />
                <Box black>修正</Box>
              </Box>
            </ToggleButton>
            <ToggleButton value="suggestion" aria-label="centered">
              <Box row toCenterY spaceX2>
                <WarningIcon fontSize="small" color="warning" />
                <Box black>建议</Box>
              </Box>
            </ToggleButton>
            <ToggleButton value="praise" aria-label="right aligned">
              <Box row toCenterY spaceX2>
                <GradeIcon fontSize="small" color="success" />
                <Box black>赞</Box>
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
          <EditableTextArea
            label={getCommentLabel()}
            text={comment ? comment : ''}
            onUpdate={(value) => {
              setComment(value)
            }}
            immediateUpdate={true}
          ></EditableTextArea>
          {type !== 'praise' && (
            <EditableTextArea
              label="修改为"
              text={correctedContent ? correctedContent : ''}
              onUpdate={(value) => {
                setCorrectedContent(value)
              }}
              immediateUpdate={true}
            ></EditableTextArea>
          )}
          <Box row spaceX2 mt2>
            {mode === 'edit' && (
              <Button
                variant="contained"
                color="warning"
                onClick={() => {
                  deleteAnnotationByID(annotation!._id!).then(() => {
                    onClose(null)
                  })
                }}
              >
                Delete
              </Button>
            )}
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                onClose(null)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (mode === 'create') {
                  createAnnotation({
                    ...annotation!,
                    comment,
                    correctedContent,
                    type,
                  }).then((newVO) => {
                    onClose(newVO)
                  })
                } else {
                  updateAnnotationProps(
                    annotation!,
                    new Map<string, any>()
                      .set('comment', comment)
                      .set('type', type)
                      .set('correctedContent', correctedContent),
                  ).then((newVO) => {
                    onClose(null)
                  })
                }
              }}
            >
              {mode === 'create' ? 'Create' : 'Update'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
export default CreateAnnotation
