import { Box } from '@fower/react'
import { Button, Dialog, DialogContent } from '@mui/material'
import { FC, useEffect } from 'react'
import EditableSelection from '../../../components/EditableSelection'
import EditableStringArray from '../../../components/EditableStringArray'
import EditableText from '../../../components/EditableText'
import EditableTextArea from '../../../components/EditableTextArea'
import {
  genreRange,
  monthRange,
  setRange,
  yearRange,
} from '../../../components/RangePreset'
import { NewCETSubjectVO, SubjectGenre } from '../../../services/subjects.service'
import { useSubjectsStore } from '../../../store/subjects.store'
export type CreateSubjectProps = {
  open: boolean
  onClose: (updated: boolean) => void
  genre: string
}

const CreateSubject: FC<CreateSubjectProps> = ({ open, onClose, genre }) => {
  const {
    initNewObject,
    newSubject,
    updateNewSubject,
    createNewSubject,
  } = useSubjectsStore()
  useEffect(() => {
    initNewObject(genre)
  }, [genre, initNewObject])
  if (newSubject === null) {
    return <></>
  }
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box column w="500px" spaceY2>
          <Box row spaceX2>
            <EditableSelection
              label="考试类型"
              options={genreRange}
              selectedValue={newSubject?.genre!}
              onChange={(newValue) => {
                updateNewSubject({ ...newSubject, genre: String(newValue) })
              }}
              hasReset={false}
            />
            <EditableSelection
              label="年"
              options={yearRange}
              selectedValue={newSubject?.year!}
              onChange={(newValue) => {
                updateNewSubject({ ...newSubject, year: Number(newValue) })
              }}
              hasReset={false}
            />

            {genre !== SubjectGenre.GRADUATE && (
              <>
                <EditableSelection
                  label="月"
                  options={monthRange}
                  selectedValue={(newSubject as NewCETSubjectVO).month}
                  onChange={(newValue) => {
                    updateNewSubject({ ...newSubject, month: Number(newValue) })
                  }}
                  hasReset={false}
                />
                <EditableSelection
                  label="套"
                  options={setRange}
                  selectedValue={`${(newSubject as NewCETSubjectVO).set}`}
                  onChange={(newValue) => {
                    updateNewSubject({ ...newSubject, set: Number(newValue) })
                  }}
                  hasReset={false}
                />
              </>
            )}
          </Box>
          <EditableText
            label="标题"
            text={newSubject?.subtitle!}
            onUpdate={(newValue) => {
              updateNewSubject({ ...newSubject, subtitle: newValue })
            }}
            immediateUpdate={true}
          />
          <EditableTextArea
            label="题面"
            text={newSubject?.directions!}
            onUpdate={(newValue) => {
              updateNewSubject({ ...newSubject, directions: newValue })
            }}
            immediateUpdate={true}
          />

          <EditableText
            label="关键词"
            text={newSubject?.keywords!}
            onUpdate={(newValue) => {
              updateNewSubject({ ...newSubject, keywords: newValue })
            }}
            immediateUpdate={true}
          />

          <EditableStringArray
            label="锦囊"
            options={newSubject?.ideas!}
            onUpdate={(newValue) => {
              updateNewSubject({ ...newSubject, ideas: newValue })
            }}
          />
          <Box row spaceX2 mt2>
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                onClose(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                createNewSubject(newSubject).then((success) => {
                  onClose(success)
                })
              }}
            >
              Create
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
export default CreateSubject
