import { Box } from '@fower/react'
import { Button, Input, MenuItem, Select } from '@mui/material'
import { FC, useState } from 'react'
import { SubjectGenre } from '../../../services/subjects.service'

const SearchBar: FC<{
  genre: string
  onSearch: (key: string | null, filterValue: string) => void
}> = ({ onSearch, genre }) => {
  const [condition, setCondition] = useState<null | string>('all')
  const [filterValue, setFilterValue] = useState<string>('')
  return (
    <Box toLeft toCenterY bgWhite rounded spaceX2 shadowGigantic>
      <Box spaceX4 bgWhite rounded p2 toAround shadowGigantic>
        <Box toCenterY w="80%" spaceX6>
          <Select
            value={condition}
            label="Condition"
            size="small"
            sx={{
              width: '100px',
            }}
            onChange={async (event) => {
              const cd = event.target.value
              setCondition(cd)
            }}
          >
            <MenuItem value={'all'} key="all">
              all
            </MenuItem>
            <MenuItem value={'year'} key="year">
              year
            </MenuItem>
            {genre === SubjectGenre.GRADUATE
              ? [
                  <MenuItem value={'part'} key="part">
                    part
                  </MenuItem>,
                  <MenuItem value={'type'} key="type">
                    type
                  </MenuItem>,
                ]
              : [
                  <MenuItem value={'month'} key="month">
                    month
                  </MenuItem>,
                  <MenuItem value={'set'} key="set">
                    set
                  </MenuItem>,
                ]}

            <MenuItem value={'genre'} key='genre'>genre</MenuItem>
          </Select>
          <Input
            type="text"
            value={filterValue}
            onChange={(event) => {
              setFilterValue(event.target.value)
            }}
            sx={{
              width: '80%',
            }}
          />
        </Box>
        <Button
          variant="contained"
          onClick={async () => {
            if (condition === 'all') {
              setFilterValue('')
            }
            onSearch(condition, filterValue)
          }}
        >
          Search
        </Button>
      </Box>
    </Box>
  )
}

export default SearchBar
