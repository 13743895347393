import { FC } from 'react'
import EditableSelection from '../EditableSelection'
import { genreRangeData } from '../RangePreset'

export type TestTypeSelectionProps = {
  selectedValue: string
  onUpdate: (newValue: string) => void
}
const TestTypeSelection: FC<TestTypeSelectionProps> = ({
  selectedValue,
  onUpdate,
}) => {
  return (
    <EditableSelection
      label="考试类型"
      selectedValue={genreRangeData.find((o) => o.id === selectedValue)?.name}
      options={genreRangeData.map((g) => g.id)}
      optionData={genreRangeData}
      onChange={(newValue) => {
        onUpdate(newValue)
      }}
      hasReset={false}
    />
  )
}
export default TestTypeSelection
