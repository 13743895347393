import cloudbase from '@cloudbase/js-sdk';
const app = cloudbase.init({
  env: 'cloud1-2gfx69kjf8d9424a',
});
const auth = app.auth({
  persistence: 'local', //用户显式退出或更改密码之前的30天一直有效
});


export const useAuth = (): cloudbase.auth.App => {
  return auth;
};

export const getDB = (): cloudbase.database.App => {
  return app.database();
};

export const getApp = (): cloudbase.app.App => {
  return app
};


export const getUser = (): cloudbase.auth.IUser | null => {
  return auth.currentUser;
};

