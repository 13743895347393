import { Box } from '@fower/react'
import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import RestartAltIcon from '@mui/icons-material/RestartAlt'
import { getDescForGenre, getGenreForName, OptionVO } from '../pages/phrase/PhrasePreset'

export type EditableSelectionProps = {
  label: string
  options: Array<string>
  optionData?: Array<OptionVO>
  selectedValue?: any
  prefix?: string
  suffix?: string
  onChange: (newValue: any) => void
  hasReset?: boolean
}

const EditableSelection: FC<EditableSelectionProps> = ({
  label,
  options,
  selectedValue,
  prefix,
  suffix,
  onChange,
  hasReset = true,
  optionData
}) => {
  const [editingValue, setEditingValue] = useState<any>(selectedValue)
  useEffect(() => {
    setEditingValue(selectedValue)
  }, [selectedValue])
  const handleChange = (event: SelectChangeEvent) => {
    const data = event.target.value
    if(optionData){
      const genreId = getGenreForName(data, optionData)
      setEditingValue(data)
      onChange(genreId)
    }else{
      setEditingValue(data)
      onChange(data)
    }
  }
  const getOptions = ()=>{
    if(!optionData){
      return options.map((data, index) => {
        return (
          <MenuItem value={data} key={`${data}-${index}`}>
            {prefix}
            {data}
            {suffix}
          </MenuItem>
        )
      })
    }
    const items = options
    return items.map((data, index) => {
      const desc = getDescForGenre(data, optionData)
      return (
        <MenuItem value={desc} key={`${data}-${index}`}>
          {prefix}
          {desc}
          {suffix}
        </MenuItem>
      )
    })
  }
  return (
    <Box row toCenterY spaceX2>
      <Box fontExtrabold mr2 black>
        {label}
      </Box>
      <Select
        value={editingValue}
        label={label}
        onChange={handleChange}
        size="small"
      >
        {getOptions()}
      </Select>
      {hasReset && (
        <Box
          onClick={() => {
            setEditingValue(selectedValue)
          }}
          black
        >
          <RestartAltIcon fontSize="small" />
        </Box>
      )}
    </Box>
  )
}

export default EditableSelection
