import { Box } from '@fower/react';
import { FC } from 'react';
import Subjects from './components/Subjects';

const Home: FC = () => {
  return (
    <Box w='100%' h='100vh' toCenter>
      <Subjects />
    </Box>
  );
};
export default Home;
