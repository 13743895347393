import { FC, useState } from 'react'
import { Box } from '@fower/react'
import MatrixSearchBar from './MatrixSearchBar'
import ActionBar from '../../../components/ActionBar'
import { Pagination } from '@mui/material'
import CreateMatrix from './CreateMatrix'
import { useMatrixSubjectsStore } from '../../../store/matrix.store'
import { useMount } from 'ahooks'
import MatrixSubjectItem from './MatrixSubjectItem'
import { getCount, MatrixSubjectVO } from '../../../services/matrix.service'
/**
 * handle matrix subject
 */
export type MatrixSubjectsProps = {
  onAddNode: (data: MatrixSubjectVO) => void
}
const perPage = 20
const MatrixSubjects: FC<MatrixSubjectsProps> = ({ onAddNode }) => {
  const {
    getSubjects,
    setSubjects,
    subjects,
    deleteSubject,
  } = useMatrixSubjectsStore()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pages, setPages] = useState<number>(0)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const refreshSubjects = async () => {
    const list = await getSubjects(currentPage, perPage)
    setSubjects(list)
    const count = await getCount(null)
    setPages(count)
  }
  useMount(async () => {
    await refreshSubjects()
  })
  return (
    <Box w="80%" h="100vh" column pt32>
      <Box w="80%" fixed top16 toCenterY toBetween zIndex="100">
        <MatrixSearchBar />
        <ActionBar
          onCreate={() => {
            setShowCreateModal(true)
          }}
        ></ActionBar>
      </Box>
      <Box w="100%" column spaceY3>
        {subjects.map((subject) => {
          return (
            <MatrixSubjectItem
              key={subject._id}
              data={subject}
              onDelete={(data) => {
                //TODO: to delete it and all nodes and options
                const sureToDelete = global.confirm('删除题目会连同一起删除全部问题，要删除吗？')
                if(sureToDelete){
                  deleteSubject(data).then(res => {
                    refreshSubjects()
                  })
                }
              }}
              onAddNode={(data) => {
                onAddNode(data)
              }}
            />
          )
        })}
      </Box>
      <Box
        fixed
        right0
        bottom0
        bgWhite
        rounded
        borderBlack
        borderSolid
        border-1
        p3
      >
        <Pagination
          count={pages}
          page={currentPage}
          onChange={(_event, page) => {
            setCurrentPage(page)
          }}
        />
      </Box>
      <CreateMatrix
        open={showCreateModal}
        onClose={() => {
          setShowCreateModal(false)
        }}
      />
    </Box>
  )
}
export default MatrixSubjects
