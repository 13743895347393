import { Box } from '@fower/react';
import { Button } from '@mui/material';
import { FC, useState } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CopyBar from '../home/components/CopyBar';
import { getObjectById, callFunction } from '../../services/common.service';
export type EVAActionBarProps = {
  onAction: () => void;
  wrId?: string;
};
const EVAActionBar: FC<EVAActionBarProps> = ({ onAction, wrId }) => {
  const [score, setScore] = useState<number>(0);
  const onCheck = async () => {
    const wrs = await getObjectById('writing-record', wrId!);
    const wr = wrs.data[0];
    const result = await callFunction('manage_writing_result', {
      action: 'getResult',
      id: wr._id,
    });
    console.log(result);
    setScore(Number(result.data.totalScore));
    const corrected = result.data.corrected;
    navigator.clipboard.writeText(corrected || '').then(() => {});
  };
  return (
    <Box row toCenterY spaceX2>
      <Button
        size='small'
        color='secondary'
        variant='contained'
        onClick={onAction}
      >
        Evaluate
      </Button>
      {wrId && (
        <>
          <CheckCircleIcon fontSize='small' color='success' />
          <CopyBar text={wrId} />
          <Button
            size='small'
            color='secondary'
            variant='contained'
            onClick={onCheck}
          >
            Check
          </Button>
          <Box black>{score}</Box>
        </>
      )}
    </Box>
  );
};
export default EVAActionBar;
