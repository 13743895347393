import { Box } from '@fower/react'
import { useMount } from 'ahooks'
import { FC, useState } from 'react'
import { useModelEssayStore } from '../../store/model-essay.store'
import { useSubjectsStore } from '../../store/subjects.store'
import ActionBar from '../../components/ActionBar'
import CreateModelEssay from './CreateModelEssay'
import ModelEssayItem from './ModelEssayItem'
import MESearchBar from './MESearchBar'
import { SubjectGenre } from '../../services/subjects.service'
import { Pagination } from '@mui/material'
import GenereSelection from '../../components/GenreSelection'

const ModelEssays: FC = () => {
  const store = useModelEssayStore()
  const { subjects, setSubjectsByGenre, getSubjects } = useSubjectsStore()
  const [openCreateModelEssay, setOpenCreateModelEssay] = useState<boolean>(
    false,
  )
  const perPage = 10
  const [genre, setGenre] = useState<string>(SubjectGenre.GRADUATE)
  const [pages, setPages] = useState<number>(0)
  const [currentSubjectID, setCurrentSubjectID] = useState<string | null>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const reloadMEs = async (
    genre: string,
    page: number,
    forceUpdate: boolean,
  ) => {
    const mes = await store.getModelEssays(
      genre,
      page - 1,
      perPage,
      forceUpdate,
    )
    store.setModelEassys(mes)
    const ps = await store.getCount(genre, null)
    setPages(Math.ceil(ps / perPage))
  }
  const reloadMEsBySubject = async (
    iGenre: string,
    page: number,
    subjectId: string | null,
    forceUpdate: boolean,
  ) => {
    setCurrentSubjectID(subjectId)
    if (subjectId === null) {
      await reloadMEs(iGenre, page, forceUpdate)
      return reloadSubjects(iGenre, forceUpdate)
    }
    const mes = await store.getModelEssaysBySubjectId(
      page - 1,
      perPage,
      subjectId,
      forceUpdate,
    )
    store.setModelEassys(mes)
    const ps = await store.getCount(iGenre, subjectId)
    setPages(Math.ceil(ps / perPage))
  }
  const reloadSubjects = async (iGenre: string, forceUpdate: boolean) => {
    setCurrentSubjectID(null)
    const sjs = await getSubjects(iGenre, 0, 60)
    setSubjectsByGenre(iGenre, sjs)
    const ps = await store.getCount(iGenre, null)
    setPages(Math.ceil(ps / perPage))
  }
  useMount(async () => {
    await reloadSubjects(genre, false)
    await reloadMEs(genre, currentPage, false)
  })
  const onCreateClose = async (updated: boolean) => {
    setOpenCreateModelEssay(false)
    await reloadMEs(genre, currentPage, updated)
  }
  return (
    <Box h="100vh" w="100%" toCenterX column pt32 spaceY2>
      <Box w="80%" fixed top16 toCenterY toBetween zIndex="100">
        <MESearchBar
          genre={genre}
          subjects={subjects}
          onSearch={(type, value) => {
            setCurrentPage(1)
            if (type === 'all') {
              reloadMEs(genre, 1, true)
            } else {
              reloadMEsBySubject(genre, 1, value, true)
            }
          }}
        />
        <GenereSelection
          selectedGenre={genre}
          onChange={async (newGenre) => {
            setGenre(newGenre)
            setCurrentPage(1)
            await reloadSubjects(newGenre, false)
            await reloadMEs(newGenre, 1, false)
          }}
        />
        <ActionBar
          onCreate={() => {
            setOpenCreateModelEssay(true)
          }}
        ></ActionBar>
      </Box>

      {store.modeleassys.map((item) => {
        return (
          <ModelEssayItem
            genre={genre}
            data={item}
            key={item._id}
            subjects={subjects}
            onUpdate={(action) => {
              if (action === 'deleted') {
                reloadMEs(genre, currentPage, true)
              }
            }}
          />
        )
      })}
      <Box
        fixed
        right0
        bottom0
        bgWhite
        rounded
        borderBlack
        borderSolid
        border-1
        p3
        zIndex="10"
      >
        <Pagination
          count={pages}
          page={currentPage}
          onChange={(_event, page) => {
            setCurrentPage(page)
            reloadMEsBySubject(genre, page, currentSubjectID, false).then(null)
          }}
        />
      </Box>
      <CreateModelEssay
        genre={genre}
        open={openCreateModelEssay}
        onClose={onCreateClose}
        subjects={subjects}
      />
    </Box>
  )
}
export default ModelEssays
