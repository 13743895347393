import { Box } from '@fower/react'
import { Button } from '@mui/material'
import { FC, useState } from 'react'
import EditableSelection from '../../components/EditableSelection'
import EditableText from '../../components/EditableText'
import { writingStatusRange } from '../../components/RangePreset'

export type WRSearchBarProps = {
  onSearch: (condition: any) => void
}
const WRSearchBar: FC<WRSearchBarProps> = ({ onSearch }) => {
  const [author, setAuthor] = useState('')
  const [status, setStatus] = useState<
    'all' | 'virgin' | 'exuberant' | 'waifu'
  >('all')

  return (
    <Box toLeft toCenterY bgWhite p2 rounded spaceX2 shadowGigantic>
      <EditableText
        label="Author"
        text={author}
        onUpdate={(newAuthor) => {
          setAuthor(newAuthor)
        }}
        immediateUpdate={true}
      />
      <EditableSelection
        label="Status"
        selectedValue={status}
        options={['all',...writingStatusRange]}
        onChange={(newStatus) => {
          setStatus(newStatus)
        }}
      />
      <Button
        size="small"
        variant="contained"
        color="warning"
        onClick={() => {
          setAuthor('')
          setStatus('all')
          onSearch({
            author: null,
            status: null,
          })
        }}
      >
        Clear
      </Button>
      <Button
        size="small"
        variant="contained"
        onClick={() => {
          let condition:{author?:string, status?:string} = {
            author, 
            status
          }
          if(author === '') {
            delete condition.author
          }
          if(status === 'all') {
            delete condition.status
          }
          onSearch(condition)
        }}
      >
        Search
      </Button>
    </Box>
  )
}

export default WRSearchBar
