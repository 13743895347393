import create from 'zustand'
import produce from 'immer'
import {
  CETSubjectVO,
  createSubject,
  getCount,
  getSubjects,
  getSubjectsCondition,
  NewCETSubjectVO,
  newEmptyObject,
  NewSubjectVO,
  SubjectGenre,
  SubjectVO,
  updateSubject,
} from '../services/subjects.service'
import { updateObjectPropsById } from '../services/common.service'
interface SubjectState {
  subjects: Array<SubjectVO | CETSubjectVO>
  newSubject: NewSubjectVO | NewCETSubjectVO | null
  initNewObject: (genre: string) => void
  getCount: (genre: string, condition: any | null) => Promise<number>
  updateSubject: (
    vo: SubjectVO | CETSubjectVO,
    key: string,
    value: any,
  ) => Promise<boolean>
  updateWholeSubject: (
    vo: SubjectVO | CETSubjectVO,
    params: Map<string, any>,
  ) => Promise<boolean>
  updateNewSubject: (value: NewSubjectVO | NewCETSubjectVO | null) => void
  createNewSubject: (value: NewSubjectVO | NewCETSubjectVO) => Promise<boolean>

  getSubjects: (
    genre: string,
    page: number,
    limit: number,
  ) => Promise<Array<SubjectVO | CETSubjectVO>>
  getSubjectsCondition: (
    genre: string,
    page: number,
    limit: number,
    condition: any | null,
  ) => Promise<Array<SubjectVO | CETSubjectVO>>
  setSubjectsByGenre: (
    genre: string,
    value: Array<SubjectVO | CETSubjectVO>,
  ) => void
}
export const useSubjectsStore = create<SubjectState>((set) => ({
  subjects: [],
  newSubject: null,
  initNewObject: (genre) => {
    set({
      newSubject: newEmptyObject(genre),
    })
  },
  getCount: async (genre: string, condition: any | null) => {
    return await getCount(genre, condition)
  },
  getSubjects: async (genre, page, limit) => {
    const result = await getSubjects(genre, page, limit)
    return result
  },
  getSubjectsCondition: async (
    genre: string,
    page: number,
    limit: number,
    condition: any | null,
  ) => {
    if (condition === null) return await getSubjects(genre, page, limit)
    const result = await getSubjectsCondition(genre, page, limit, condition)
    return result
  },
  setSubjectsByGenre: (
    genre: string,
    value: Array<SubjectVO | CETSubjectVO>,
  ) => {
    set(() => ({
      subjects: value,
    }))
  },
  createNewSubject: async (
    value: NewSubjectVO | NewCETSubjectVO,
  ): Promise<boolean> => {
    const result = await createSubject(value)
    return result
  },
  updateSubject: async (
    vo: SubjectVO | CETSubjectVO,
    key: string,
    value: any,
  ) => {
    const result = await updateSubject(vo, key, value)
    if (result) {
      set(
        produce((state) => {
          const newSubjects = state.subjects.map((s: any) => {
            if (s._id === vo._id) {
              return { ...vo }
            }
            return s
          })
          state.subjects = newSubjects
        }),
      )
    }
    return result
  },
  updateWholeSubject: async (
    vo: SubjectVO | CETSubjectVO,
    params: Map<string, any>,
  ) => {
    const collectionName =
      vo.genre === SubjectGenre.GRADUATE ? 'subject' : 'subject-cet'
    const result = await updateObjectPropsById(collectionName, vo._id, params)
    if (result) {
      set(
        produce((state) => {
          const newSubjects = state.subjects.map((s: any) => {
            if (s._id === vo._id) {
              return { ...vo }
            }
            return s
          })
          state.subjects = newSubjects
        }),
      )
    }
    return result
  },
  updateNewSubject: (value: NewSubjectVO | NewCETSubjectVO | null) => {
    set({
      newSubject: value,
    })
  },
}))
