import { FC } from 'react'
import { Box } from '@fower/react'
import { MatrixOptionNodeVO } from '../../../services/matrix.service'
import { Drawer } from '@mui/material'

export type PreviewWritingProps = {
  options: MatrixOptionNodeVO[]
  isOpen: boolean
  onClose: () => void
}
const PreviewWriting: FC<PreviewWritingProps> = ({
  options,
  isOpen,
  onClose,
}) => {
  return (
    <Drawer
      anchor="right"
      open={isOpen}
      onClose={() => {
        onClose()
      }}
    >
      <Box w="480px" h-100p bgWhite p6 column spaceY4>
        {options
          .filter((o) => o.phrase !== '')
          .map((o) => {
            return (
              <Box
                key={o._id}
                w-100p
                p3
                border="solid 1px rgba(0,0,0,0.5)"
                rounded
              >
                {o.phrase}
              </Box>
            )
          })}
      </Box>
    </Drawer>
  )
}
export default PreviewWriting
